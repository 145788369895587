export const ObjectShelvesIcon = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M8.13372 3.00049H15.8669C18.815 3.00049 20.6501 5.08168 20.6501 8.02687V15.9741C20.6501 18.9193 18.815 21.0005 15.866 21.0005H8.13372C5.18561 21.0005 3.35156 18.9193 3.35156 15.9741V8.02687C3.35156 5.08168 5.19437 3.00049 8.13372 3.00049Z"
				stroke="#0047FF"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10.043 7.77783H13.9563M10.043 16.2229H13.9563"
				stroke="#0047FF"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M3.46484 12.0005H20.5347"
				stroke="#0047FF"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
