import { useQuery } from "@tanstack/react-query"
import downloadFileService from "services/downloadFile.service"

export const useFileDownload = () => {
	const downloadFile = async (url: string, fileName: string, type: string) => {
		await downloadFileService.fileDownload(url, fileName, type)
	}

	return { downloadFile }
}

const fetchPresignedDownloadUrl = async (url: string) => {
	const response = await downloadFileService.getPresignedDownloadUrl(url)
	return response.data
}

export const usePresignedDownloadUrl = (url: string | null) => {
	return useQuery({
		queryKey: ["presignedDownloadUrl", url],
		queryFn: () => fetchPresignedDownloadUrl(url!),
		enabled: !!url, // Only fetch if the URL is not null
		retry: false, // No retries for this query
	})
}
