import { TreeDotsIcon } from "components/ui/icons/threeDots/ThreeDots"
import style from "./ButtonOptions.module.scss"

const { buttonOptions } = style

type Props = {
	className?: string
}

export const ButtonOptions = ({ className = "" }: Props) => {
	return (
		<button className={`${className} ${buttonOptions}`} data-testid="ButtonOptions">
			<TreeDotsIcon />
		</button>
	)
}
