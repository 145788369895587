import { TableColumnsType } from "antd"
import { Tooltip } from "components/ui/tooltip/Tooltip"
import { TObjectsConfigurationsTable } from "types/drawer.type"
import { ConfigurationsObjectsType } from "../ConfigurationsObjectsType/ConfigurationsObjectsType"
import { InModelTrueIcon } from "components/ui/icons/objectsTable/inModel/InModelTrueIcon"
import { InModelFalseIcon } from "components/ui/icons/objectsTable/inModel/InModelFalseIcon"
import { objectTypeTextTooltip } from "helpers/utils.helper"
import style from "./ConfigurationsObjects.module.scss"

const { modelNumbers, objectType } = style

export const ConfigurationsObjectsColumns = () => {
	const tableColumns: TableColumnsType<TObjectsConfigurationsTable> = [
		{
			title: "NAME",
			dataIndex: "name",
			key: "name",
			width: 150,
		},
		{
			title: "",
			dataIndex: "type",
			key: "type",
			width: 50,
			render: (item) => {
				const getTooltipText = objectTypeTextTooltip(item)

				return (
					<Tooltip text={getTooltipText} placement="bottom">
						<div className={objectType}>
							<ConfigurationsObjectsType type={item} />
						</div>
					</Tooltip>
				)
			},
		},
		{
			title: "LABEL",
			dataIndex: "modelLable",
			key: "modelLable",
			width: 100,
		},
		{
			title: "NUMBERS",
			dataIndex: "modelNumbers",
			key: "modelNumbers",
			width: 200,
			render: (item) =>
				item.map((item: string) => (
					<span key={item} className={modelNumbers}>
						{item}
					</span>
				)),
		},
		{
			title: "IN MODEL",
			dataIndex: "includedInLatestModelTrain",
			key: "includedInLatestModelTrain",
			width: 50,
			render: (item) => {
				return item ? (
					<Tooltip placement="bottom" text="Included in the latest model train">
						<span>
							<InModelTrueIcon />
						</span>
					</Tooltip>
				) : (
					<Tooltip placement="bottom" text="Not included in the latest model train">
						<span>
							<InModelFalseIcon />
						</span>
					</Tooltip>
				)
			},
		},
		{
			title: "OBJ. COUNT",
			dataIndex: "objectCount",
			key: "objectCount",
			width: 50,
		},
		{
			title: "PROCESS",
			dataIndex: "includedInProcessingResult",
			key: "includedInProcessingResult",
			width: 40,
			render: (item) =>
				// Don't display the icon if not icnluded in last model train
				item.includedInLatestModelTrain ? (
					// Display specific icon if included in last model train
					item.includedInProcessingResult ? (
						<InModelTrueIcon />
					) : (
						<InModelFalseIcon />
					)
				) : null,
		},
	]
	return { tableColumns }
}
