import { Modal } from "components/ui/modal/Modal"
import { ButtonPrimary } from "components/ui/buttons/ButtonPrimary/ButtonPrimary"
import { ButtonWarning } from "components/ui/buttons/ButtonWarning/ButtonWarning"
import { useDeleteConfirmModalStore } from "store/modal.store"
import style from "./DeleteConfirmationModal.module.scss"

const { modal, buttons, fileName } = style

type Props = {
	deleteFile: {
		id: number
		fileName: string
	}
	deleteFileHandle: () => void
}

export const DeleteConfirmationModal = ({ deleteFile, deleteFileHandle }: Props) => {
	const { isDeleteConfirmModalOpen, setDeleteConfirmDodalClose } = useDeleteConfirmModalStore()

	return (
		<Modal
			className={modal}
			title="Delete file"
			isModalOpen={isDeleteConfirmModalOpen}
			setModalClose={setDeleteConfirmDodalClose}
			data-testid="confirmationDeletionModal"
		>
			<p>
				Are you sure you want to permanently delete <span className={fileName}>{`${deleteFile.fileName}`}?</span>
			</p>
			<div className={buttons}>
				<ButtonPrimary onClick={setDeleteConfirmDodalClose}>Cancel</ButtonPrimary>
				<ButtonWarning onClick={deleteFileHandle}>Delete file</ButtonWarning>
			</div>
		</Modal>
	)
}
