import { create } from "zustand"
import type { TDrawer, TGeneralFeedbackProps, TThankYouMessage } from "types/drawer.type"

export const useDrawerStore = create<TDrawer>((set) => ({
	isDrawerOpen: false,
	setDrawerOpen: () => set(() => ({ isDrawerOpen: true })),
	setDrawerClose: () => set(() => ({ isDrawerOpen: false })),
}))

export const useGeneralFeedbackStore = create<TGeneralFeedbackProps>((set) => ({
	generalFeedback: "",
	setGeneralFeedback: (feedback) => set(() => ({ generalFeedback: feedback })),
}))

export const useThankYouMessageStore = create<TThankYouMessage>((set) => ({
	thankYouMessage: false,
	setThankYouMessage: (message) => set(() => ({ thankYouMessage: message })),
}))
