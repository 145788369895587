import { connect, NatsConnection, JSONCodec, ConnectionOptions } from "nats.ws"

export class NatsService {
	private nc: NatsConnection | null = null
	private jc = JSONCodec()
	private closing = false

	async connect(servers: string): Promise<void> {
		const connectionOptions: ConnectionOptions = {
			servers: servers,
			waitOnFirstConnect: true,
		}
		this.nc = await connect(connectionOptions)
	}

	subscribe<T>(subject: string, callback: (message: T, subject: string) => void) {
		if (!this.nc) {
			console.error("NATS connection is not established.")
			return
		}

		return this.nc.subscribe(subject, {
			callback: (e, msg) => {
				if (e) {
					console.error("Error in subscription:", e)
					return
				}
				const message = this.jc.decode(msg.data) as T
				callback(message, msg.subject)
			},
		})
	}

	async close() {
		if (this.nc && !this.closing) {
			this.closing = true
			await this.nc.drain()
			this.nc = null
			this.closing = false
		}
	}
}
