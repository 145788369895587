import { Table } from "components/ui/tables/Table/Table"
import { TeamTableColumns } from "./TeamTableColumns"
import type { IUsersTable } from "types/table.type"
import style from "./TeamTable.module.scss"

const { pageTeamTable } = style

export const TeamTable = ({ allUsers }: { allUsers: IUsersTable[] }) => {
	const tableData = allUsers?.map((item) => ({
		id: item.id,
		key: item.id,
		name: `${item.name} ${item.secondName}`,
		secondName: item.secondName,
		availableRoles: item.availableRoles,
		email: item.email,
		role: item.role,
		action: {
			role: item.role,
			availableRoles: item.availableRoles,
			id: item.id,
			name: `${item.name} ${item.secondName}`,
		},
	}))

	// Pass columns to table
	const columns = TeamTableColumns()

	return (
		<div className={pageTeamTable}>
			<Table<IUsersTable> data={tableData} columns={columns} />
		</div>
	)
}
