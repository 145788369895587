type Props = {
	active: boolean
}

export const ThumbsUpIcon = ({ active }: Props) => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M5.36453 8.65625C4.7942 8.65625 4.50903 8.65625 4.27581 8.72205C3.68947 8.88747 3.23122 9.34572 3.0658 9.93206C3 10.1653 3 10.4504 3 11.0206C3 13.3982 3 15.8688 3 18.2464C3 18.8166 3 19.1017 3.0658 19.3349C3.23122 19.9213 3.68947 20.3795 4.27581 20.545C4.50903 20.6107 4.7942 20.6108 5.36453 20.6108C5.93486 20.6108 6.22003 20.6107 6.45325 20.545C7.03959 20.3795 7.49783 19.9213 7.66326 19.3349C7.72906 19.1017 7.72906 18.8166 7.72906 18.2464C7.72906 15.8688 7.72906 13.3982 7.72906 11.0206C7.72906 10.4504 7.72906 10.1653 7.66326 9.93206C7.49783 9.34572 7.03959 8.88747 6.45325 8.72205C6.22003 8.65625 5.93486 8.65625 5.36453 8.65625Z"
				stroke={active ? "#0047FF" : "black"}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.72852 9.73767C9.17424 7.86845 10.6084 5.98961 12.066 4.12964C12.5278 3.54035 13.3364 3.24353 14.0623 3.45456C15.6813 3.92523 15.268 6.79664 14.8446 8.31949C14.5306 9.44877 14.9124 9.84047 15.8317 9.84047C17.842 9.84047 18.7972 9.84047 19.4816 10.2517C20.1075 10.6278 20.567 11.2278 20.7672 11.93C21.1845 13.3942 21.0408 16.1834 20.3255 18.1274C19.8424 19.4408 18.4795 20.344 17.299 20.475C15.705 20.6519 13.48 20.5881 11.8001 20.4948C10.2564 20.4091 8.81876 19.7223 7.72852 18.6261"
				stroke={active ? "#0047FF" : "black"}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
