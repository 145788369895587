import { H2 } from "components/ui/typography/titles/Titles"
import { ButtonPrimary } from "components/ui/buttons/ButtonPrimary/ButtonPrimary"
import { useRegistrationStore } from "store/auth.store"
import { useResendInvintation } from "hooks/auth.hook"
import { useMessage } from "hooks/message.hook"
import { AxiosError } from "axios"
import style from "./CheckEmail.module.scss"

const { title, text } = style

export const CheckEmail = () => {
	const { data } = useRegistrationStore()
	const { mutate } = useResendInvintation()
	const { success, error } = useMessage()

	const resendInvintationHandle = () => {
		mutate(data.email, {
			onSuccess: () => {
				success("We have resent the invitation email")
			},
			onError: (e) => {
				if (e instanceof AxiosError) {
					const status = e.response?.status
					if (status === 400) {
						error("Unable to create account")
					} else if (status != 400) {
						error("Something went wrong, try again later")
					}
				}
			},
		})
	}

	return (
		<>
			<H2 className={title}>Check your email</H2>
			<p className={text}>Thank you for registering! We have sent a link to {data.email} to activate your account.</p>
			<ButtonPrimary onClick={resendInvintationHandle}>Resend email</ButtonPrimary>
		</>
	)
}
