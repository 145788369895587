import { ConfigProvider } from "antd"

type Props = {
	children: React.ReactNode
}

export const InputProvider = ({ children }: Props) => {
	return (
		<ConfigProvider
			theme={{
				token: {
					borderRadiusLG: 12,
				},
			}}
		>
			{children}
		</ConfigProvider>
	)
}

export const TextAreaProvider = ({ children }: Props) => {
	return (
		<ConfigProvider
			theme={{
				components: {
					Input: {
						paddingBlock: 10,
					},
				},
				token: {
					colorBorder: "#DEE0EB",
					colorTextPlaceholder: "#888DA6",
					borderRadius: 12,
				},
			}}
		>
			{children}
		</ConfigProvider>
	)
}

export const InputNumberBorderlessProvider = ({ children }: Props) => {
	return (
		<ConfigProvider
			theme={{
				components: {
					Input: {
						paddingInlineLG: 5,
					},
				},
			}}
		>
			{children}
		</ConfigProvider>
	)
}
