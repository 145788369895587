export const TreeDotsIcon = () => {
	return (
		<svg
			data-testid="ThreeDotsIcon"
			width="32"
			height="5"
			viewBox="0 0 32 5"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="9" cy="2.5" r="2" fill="#1C1C1F" />
			<circle cx="16" cy="2.5" r="2" fill="#1C1C1F" />
			<circle cx="23" cy="2.5" r="2" fill="#1C1C1F" />
		</svg>
	)
}
