import { Input as AntInput } from "antd"
import { TOnChangeInput } from "types/default.type"
import type { SizeType } from "antd/es/config-provider/SizeContext"

type Props = {
	onChange?: (args: TOnChangeInput) => void
	width?: string
	size?: SizeType
	disabled?: boolean
	value?: string
}

export const Input = ({ width = "100%", onChange, size = "large", disabled = false, value }: Props) => {
	return <AntInput style={{ width: width }} onChange={onChange} size={size} disabled={disabled} value={value} />
}
