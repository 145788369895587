export const InModelTrueIcon = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				opacity="0.2"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.4389 2H7.56C4.23368 2 2 4.33684 2 7.81684V16.1832C2 19.6621 4.23368 22 7.56 22H16.4379C19.7642 22 22 19.6621 22 16.1832V7.81684C22 4.33684 19.7653 2 16.4389 2Z"
				fill="#4CD10D"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.9929 10.2222L11.3749 14.8402C11.2279 14.9872 11.0369 15.0602 10.8449 15.0602C10.6519 15.0602 10.4609 14.9872 10.3139 14.8402L8.00491 12.5312C7.71191 12.2382 7.71191 11.7632 8.00491 11.4702C8.29791 11.1772 8.77191 11.1772 9.06491 11.4702L10.8449 13.2492L14.9319 9.16116C15.2249 8.86816 15.6999 8.86816 15.9929 9.16116C16.2859 9.45416 16.2859 9.92916 15.9929 10.2222Z"
				fill="#4CD10D"
			/>
		</svg>
	)
}
