import { FirstScreen } from "components/ui/FirstScreen/FirstScreen"
import { FormAuth } from "./layout/FormAuth"

export const PageAuth = () => {
	return (
		<FirstScreen>
			<FormAuth />
		</FirstScreen>
	)
}
