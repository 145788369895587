import { Radio, RadioChangeEvent, Space } from "antd"
import { SizeType } from "antd/es/config-provider/SizeContext"
import { CustomFilterProvider } from "components/providers/CustomFilter.provider"
import type { FilterDropdownProps } from "antd/es/table/interface"
import style from "./CustomFilter.module.scss"

const { customFilter, filterName, filterHeader, cleanButton } = style

interface FilterOption {
	text: string
	value: string
}

interface Props extends FilterDropdownProps {
	filteredOptions: FilterOption[]
	size?: SizeType
	dataIndex: string
	clearFilters: () => void
}

export const CustomFilter = ({
	filteredOptions,
	selectedKeys,
	setSelectedKeys,
	clearFilters,
	confirm,
	dataIndex,
	size = "large",
}: Props) => {
	const stringSlectedKey = selectedKeys[0]

	const handleRadioChange = (e: RadioChangeEvent) => {
		setSelectedKeys([e.target.value])
		confirm()
	}

	const capitalizeFirstLetter = (string: string) => {
		return string.charAt(0).toUpperCase() + string.slice(1)
	}

	return (
		<div className={customFilter}>
			<div className={filterHeader}>
				<span className={filterName}>{capitalizeFirstLetter(dataIndex)}</span>
				<button
					className={cleanButton}
					onClick={() => {
						clearFilters()
						confirm()
					}}
				>
					Clean
				</button>
			</div>
			<CustomFilterProvider>
				<Radio.Group value={stringSlectedKey} onChange={handleRadioChange} size={size}>
					<Space direction="vertical">
						{filteredOptions
							? filteredOptions.map((option) => (
									<Radio key={option.value} value={option.value}>
										{option.text}
									</Radio>
								))
							: null}
					</Space>
				</Radio.Group>
			</CustomFilterProvider>
		</div>
	)
}
