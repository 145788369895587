import { useCallback, useEffect, useState } from "react"
import { Collapse } from "components/ui/collapse/Collapse"
// import { InputSearch } from "components/ui/inputs/InputSearch/InputSearch"
import { FoundItemsSingleObject } from "../FoundItemsSingleObject/FoundItemsSingleObject"
import { useDrawingObjects } from "hooks/drawings.hook"
import { Skeleton } from "components/ui/skeleton/Skeleton"
import { useSearchParams } from "react-router-dom"
import type { CollapseProps } from "antd"
import type { TFoundObjectsTopLevelCategory, TFoundObjectsSubitem, TDrawingStatusProps } from "types/drawer.type"
import style from "./FoundItemsObjects.module.scss"

const { foundItemsObject, collapse, ul, subCollapse, superCategoryTotalCount, skeleton, message } = style

export const FoundItemsObjects = ({ drawingStatus }: TDrawingStatusProps) => {
	const [collapseItems, setCollapseItems] = useState<CollapseProps["items"]>()
	const [isObjectsEmpty, setIsObjectEmpty] = useState<boolean>()

	const [searchParams] = useSearchParams()
	const drawerId = Number(searchParams.get("drawingID"))
	const processingId = Number(searchParams.get("processingID"))

	const {
		data: drawingObjects,
		isPending: drawingObjectsIsPending,
		isError: errorFetchingObjects,
	} = useDrawingObjects(drawerId, processingId, drawingStatus)

	// Super category count
	const subCollapseExtraItem = (items: TFoundObjectsSubitem) => {
		const totalSum = Object.values(items).reduce((sum, value) => sum + value, 0)
		return <span className={superCategoryTotalCount}>{totalSum}</span>
	}

	// Generate super category Collapse items
	const generateSubCollapseItems = useCallback((item: TFoundObjectsTopLevelCategory, topLevelCategory: string) => {
		return Object.entries(item[topLevelCategory]).map(([superCategory, subItems]) => ({
			key: superCategory,
			label: superCategory,
			extra: subCollapseExtraItem(subItems),
			children: (
				<ul className={ul}>
					{Object.entries(subItems).map(([subItem, subValue]) => (
						<FoundItemsSingleObject key={subItem} subItem={subItem} subValue={subValue} />
					))}
				</ul>
			),
		}))
	}, [])

	// Generate top level category Collapse items
	const generateCollapseItems = useCallback(
		(apiData: TFoundObjectsTopLevelCategory[]) => {
			// Ensure the data is an array
			const dataArray = Array.isArray(apiData) ? apiData : [apiData]

			return dataArray.flatMap((item, mainIndex) =>
				Object.keys(item).map((topLevelCategory, subIndex) => ({
					key: `${mainIndex}-${subIndex}`,
					label: topLevelCategory,
					children: (
						<div className={subCollapse}>
							<Collapse items={generateSubCollapseItems(item, topLevelCategory)} />
						</div>
					),
				})),
			)
		},
		[generateSubCollapseItems],
	)

	useEffect(() => {
		if (drawingObjects) {
			const collapseItems = generateCollapseItems(drawingObjects)
			setCollapseItems(collapseItems)

			const empty = Object.keys(collapseItems).length === 0
			setIsObjectEmpty(empty)
		}
	}, [drawingObjects, generateCollapseItems])

	if (drawingObjectsIsPending) {
		return (
			<div className={skeleton} data-testid="objectsSkeleton">
				<Skeleton rows={12} />
			</div>
		)
	}

	return (
		<div className={foundItemsObject}>
			{isObjectsEmpty || errorFetchingObjects
				? ""
				: // <div className={search}>
					// 	<InputSearch disabled />
					// </div>
					""}
			{isObjectsEmpty ? (
				<span className={message}>No objects found</span>
			) : errorFetchingObjects ? (
				<span className={message}>Something went wrong, please try again later</span>
			) : (
				<div className={collapse}>
					<Collapse items={collapseItems} />
				</div>
			)}
		</div>
	)
}
