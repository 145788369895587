/* eslint-disable @typescript-eslint/no-explicit-any */
import { MutableRefObject, useEffect, useRef } from "react"
import WebViewer from "@pdftron/pdfjs-express"

const licenseKey = import.meta.env.VITE_PDF_JS_EXPRESS_KEY

type Props = {
	fileLink: string | undefined
	previewRef: MutableRefObject<null>
}

export const useOpenFilePreview = ({ fileLink, previewRef }: Props) => {
	const instanceRef = useRef<any>(null)

	useEffect(() => {
		// Initialize WebViewer if it's not already initialized
		if (previewRef.current && !instanceRef.current) {
			WebViewer(
				{
					licenseKey: licenseKey,
					path: "/PDFjs",
					initialDoc: fileLink,
				},
				previewRef.current,
			)
				.then((viewerInstance: any) => {
					console.log("WebViewer initialized", viewerInstance)
					instanceRef.current = viewerInstance
					if (fileLink) {
						viewerInstance.UI.loadDocument(fileLink)
					}
				})
				.catch((error: any) => {
					console.error("WebViewer initialization failed", error)
				})
		} else if (instanceRef.current && fileLink) {
			// Use the existing WebViewer instance to load a new document
			instanceRef.current.UI.loadDocument(fileLink)
		}
	}, [fileLink, previewRef])
}
