import { CSSProperties } from "react"
import style from "./Keyword.module.scss"

const { keyword } = style

type Props = {
	children: string
	style: CSSProperties
}

export const Keyword = ({ style, children }: Props) => {
	return (
		<div className={keyword} style={style} data-testid="dropdownTrigger">
			{children}
		</div>
	)
}
