import { useEffect, useState } from "react"
import { ButtonPrimary } from "components/ui/buttons/ButtonPrimary/ButtonPrimary"
import { UploadFileIcon } from "components/ui/icons/button/UploadFileIcon"
import { Upload } from "antd"
import { getMD5Hash } from "helpers/getMD5Hash.helper"
import { RcFile } from "antd/es/upload"
import { useUploadFileHash, useUploadFinished } from "hooks/uploadFiles.hook"
import { useUploadFile } from "hooks/uploadFiles.hook"
import {
	useFilesToAbortStore,
	useUploadFilesDataStore,
	useUploadbarEnabledStore,
	useUploadbarOpenStore,
} from "store/uploadFiles.store"
import { useMessage } from "hooks/message.hook"
import { AxiosError, AxiosProgressEvent } from "axios"
import { Spin } from "components/ui/spin/Spin"
import { useDrawings } from "hooks/drawings.hook"
import { TUploadFinished } from "types/uploadFiles.type"
import style from "./UploadFiles.module.scss"

const { button } = style
interface ExtendedRcFile extends RcFile {
	processingUpload?: boolean
}

export const UploadFiles = () => {
	const { setUploadbarEnabled, setUploadbarDisabled } = useUploadbarEnabledStore()
	const { setFilesData, setUpdateFilesData, setClearFilesData } = useUploadFilesDataStore()
	const { setFileController, resetIdsToAbort } = useFilesToAbortStore()
	const { setOpen } = useUploadbarOpenStore()

	const { error, success } = useMessage()
	const { refetch: refetchDrawings } = useDrawings()
	const { mutateAsync: uploadFileHash, isPending: fileHashIsPending } = useUploadFileHash()
	const { mutateAsync: uploadFile } = useUploadFile()
	const { mutateAsync: uploadFinished } = useUploadFinished()

	const [isMD5HashIsCalculating, setIsMD5HashIsCalculating] = useState(false)
	const [fileUploadStatuses, setFileUploadStatuses] = useState({})

	const calculateHashAndUpload = async (file: ExtendedRcFile) => {
		setIsMD5HashIsCalculating(true)

		try {
			file.processingUpload = true // Mark file as being processed
			setFileUploadStatuses((prev) => ({ ...prev, [file.uid]: "uploading" }))

			const hash = await getMD5Hash(file)
			await uploadFileWithData(file, hash)
		} catch (e) {
			// Error handling only for non AxiosErros
			if (!(e instanceof AxiosError)) {
				processUploadError(e, file)
			}
		} finally {
			setIsMD5HashIsCalculating(false)
		}
	}

	const uploadFileWithData = async (file: ExtendedRcFile, hash: string) => {
		const uploadFinishedData = { uploadId: "", result: "pending", reason: "" }

		try {
			const data = await uploadFileHash({ hash, fileName: file.name }).catch(() => {
				setFileUploadStatuses((prev) => ({ ...prev, [file.uid]: "failed" }))
				setUpdateFilesData({ id: file.uid, status: "failed" })
				error(`Upload error for file: ${file.name}. Please, try to upload the file again`)
			})

			setUploadbarEnabled() // Enable Uploadbar
			setOpen() // Open Uploadbar

			const presignedUrl = data?.data?.upload_presigned_url
			uploadFinishedData.uploadId = data?.data?.uploadId

			const onProgress = (progressEvent: AxiosProgressEvent) => {
				setFilesData({
					id: file.uid,
					name: file.name,
					progress: progressEvent.progress,
					estimated: progressEvent.estimated,
					status: "uploading",
				})
			}

			const controller = new AbortController() // Set up AbortController
			setFileController(file.uid, controller) // Set controller with ID to the store

			// Attempt to upload file using obtained presigned URL
			await uploadFile({ presignedUrl, file, onProgress, abortController: controller }).catch(() => {
				setFileUploadStatuses((prev) => ({ ...prev, [file.uid]: "failed" }))
				setUpdateFilesData({ id: file.uid, status: "failed" })
			})

			uploadFinishedData.uploadId = data?.data?.uploadId
			uploadFinishedData.result = "success"
		} catch (e) {
			if (e instanceof AxiosError) {
				processUploadError(e, file, uploadFinishedData)
			}
		} finally {
			if (uploadFinishedData.uploadId) {
				await uploadFinished(uploadFinishedData)
					.then(() => {
						setUpdateFilesData({ id: file.uid, status: "uploaded" })
						setFileUploadStatuses((prev) => ({ ...prev, [file.uid]: "uploaded" }))
					})
					.catch(() => {
						setFileUploadStatuses((prev) => ({ ...prev, [file.uid]: "failed" }))
						setUpdateFilesData({ id: file.uid, status: "failed" })
						error(`Upload error for file: ${file.name}. Please, try to upload the file again`)
					})
				await refetchDrawings()
			}
		}
	}

	const processUploadError = (e: AxiosError | unknown, file: ExtendedRcFile, uploadFinishedData?: TUploadFinished) => {
		setFileUploadStatuses((prev) => ({ ...prev, [file.uid]: "failed" }))

		if (e instanceof AxiosError) {
			const message = e.message

			if (message === "canceled") {
				setUpdateFilesData({
					id: file.uid,
					status: "canceled",
				})
				if (uploadFinishedData) uploadFinishedData.result = "canceled"
			}

			if (message != "canceled") {
				error(`Upload error for file: ${file.name}. Please, try to upload the file again`)

				if (uploadFinishedData) uploadFinishedData.result = "failed"
				if (uploadFinishedData) uploadFinishedData.reason = message || "Unknown error"

				setUpdateFilesData({
					id: file.uid,
					status: "failed",
				})
			}
		} else {
			error("Your file may be corrupted, please re-upload")
		}
	}

	const beforeUploadHandle = async (_: RcFile, fileList: ExtendedRcFile[]) => {
		// const validFiles = fileList.filter((file) => file && file.uid)

		const uploadPromises = fileList.map(async (file) => {
			if (file.processingUpload) return
			await calculateHashAndUpload(file)
		})

		// Await all uploads to complete
		await Promise.all(uploadPromises)

		return false // Prevent default upload behavior
	}

	useEffect(() => {
		// Only perform the check if there are files being processed
		if (Object.keys(fileUploadStatuses).length > 0) {
			const allUploaded = Object.values(fileUploadStatuses).every((status) => status === "uploaded")

			if (allUploaded) {
				success("Upload successful")
				resetIdsToAbort() // Clear all abort IDs

				setFileUploadStatuses({})
			}
		}
	}, [fileUploadStatuses, resetIdsToAbort, success, setUploadbarDisabled, setClearFilesData])

	return (
		<Upload
			accept=".pdf"
			name="upload"
			beforeUpload={beforeUploadHandle}
			showUploadList={false}
			data-testid="uploadFiles"
			multiple
		>
			{fileHashIsPending || isMD5HashIsCalculating === true ? (
				<Spin />
			) : (
				<ButtonPrimary className={button}>
					<UploadFileIcon />
					Upload files
				</ButtonPrimary>
			)}
		</Upload>
	)
}
