import { Progress } from "antd"

type Props = {
	progress: number | undefined
}

export const UploadbarProgress = ({ progress }: Props) => {
	const progressPercent = progress && progress * 100
	return <Progress type="circle" percent={progressPercent} size={30} showInfo={false} />
}
