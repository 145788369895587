import { ButtonPrimary } from "components/ui/buttons/ButtonPrimary/ButtonPrimary"
import style from "./AddModelObject.module.scss"

const { addModelObjectButton } = style

export const AddModelObject = () => {
	return (
		<ButtonPrimary className={addModelObjectButton} submittable={false}>
			Add model object
		</ButtonPrimary>
	)
}
