import { useCallback } from "react"
import { Collapse } from "components/ui/collapse/Collapse"
import { TableObjects } from "components/ui/tables/TableObjects/TableObjects"
import type { ColumnsType } from "antd/es/table"
import type { TTopLevelCategory } from "types/drawer.type"
import type { TObjectsConfigurationsTable } from "types/drawer.type"

export const useCollapseObjects = (columns: ColumnsType<TObjectsConfigurationsTable>) => {
	// Generate top level category Collapse items
	const generateSubCollapseItems = useCallback(
		(item: TTopLevelCategory, topLevelCategory: string) => {
			return Object.entries(item[topLevelCategory]).map(([superCategory, subItems]) => {
				// Data for table
				const data = Object.values(subItems).map((subItem) => ({
					key: subItem.id,
					name: subItem.name,
					type: subItem.type,
					modelLable: subItem.category,
					modelNumbers: subItem.front_model_numbers,
					objectCount: subItem.number_of_object_included_in_last_train,
					includedInLatestModelTrain: subItem.included_in_latest_model_train,
					includedInProcessingResult: {
						includedInProcessingResult: subItem.included_in_processing_result,
						includedInLatestModelTrain: subItem.included_in_latest_model_train,
						categoryId: subItem.id,
					},
					action: {
						name: subItem.name,
						modelNumbers: subItem.front_model_numbers,
						categoryId: subItem.id,
					},
				}))

				// Render table for each super category
				return {
					key: superCategory,
					label: superCategory,
					children: (
						<div className="tableObjects">
							<TableObjects columns={columns} data={data} scrollY={600} />
						</div>
					),
				}
			})
		},
		[columns],
	)

	const generateCollapseItems = useCallback(
		(objectsConfig: TTopLevelCategory[]) => {
			if (objectsConfig) {
				const objectsArray = Array.isArray(objectsConfig) ? objectsConfig : [objectsConfig]

				return objectsArray.flatMap((item, mainIndex) =>
					Object.keys(item).map((topLevelCategory, subIndex) => ({
						key: `${mainIndex}-${subIndex}`,
						label: topLevelCategory,
						children: (
							<div style={{ paddingLeft: "40px" }}>
								<Collapse items={generateSubCollapseItems(item, topLevelCategory)} />
							</div>
						),
					})),
				)
			}
		},
		[generateSubCollapseItems],
	)

	return { generateCollapseItems }
}
