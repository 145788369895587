import { format, parse } from "date-fns"

export const formatDate = (date: string) => {
	const formatedDate = format(new Date(date), "dd LLLL yyyy")
	return formatedDate
}

export const firstMonthFormatDate = (date: string) => {
	try {
		// Parse the input date string
		const parsedDate = parse(date, "dd/MM/yyyy", new Date())

		// Format the parsed date to the desired output format
		return format(parsedDate, "MMM dd, yyyy")
	} catch (error) {
		console.error("Invalid date format:", error)
		return null
	}
}
