import { ReactNode } from "react"
import { ConfigProvider } from "antd"

type Props = {
	children: ReactNode
}

export const CustomFilterProvider = ({ children }: Props) => {
	return (
		<ConfigProvider
			theme={{
				token: {
					fontSize: 18,
					borderRadius: 34,
					borderRadiusLG: 34,
					borderRadiusSM: 34,
				},
				components: {
					Radio: {
						radioSize: 24,
						dotSize: 18,
						buttonBg: "#BEC2D6",
						buttonCheckedBg: "#BEC2D6",
						buttonSolidCheckedColor: "#BEC2D6",
					},
				},
			}}
		>
			{children}
		</ConfigProvider>
	)
}
