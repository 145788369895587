import { ReactNode } from "react"
import { Tooltip as AntTooltip } from "antd"
import { TooltipProvider } from "components/providers/Tooltip.provider"
import type { TooltipPlacement } from "antd/es/tooltip"

type Props = {
	children: ReactNode
	text: string | undefined
	color?: string
	placement: TooltipPlacement
}

export const Tooltip = ({ children, text, placement, color = "#F2F3F7" }: Props) => {
	return (
		<TooltipProvider>
			<AntTooltip title={text} color={color} placement={placement}>
				{children}
			</AntTooltip>
		</TooltipProvider>
	)
}
