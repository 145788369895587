import { useEffect } from "react"
import { useUserProfile } from "hooks/user.hook"
import { Dropdown } from "../dropdown/Dropdown"
import type { MenuProps } from "antd"
import { LogoutIcon } from "../icons/logout/LogoutIcon"
import { useCredentialsStore } from "store/auth.store"
import { useNavigate } from "react-router-dom"
import { useDestroyToken } from "hooks/auth.hook"
import { AxiosError } from "axios"
import { useMessage } from "hooks/message.hook"
import { useCurrentUserStore } from "store/user.store"
import { usePaginationStore } from "store/table.store"
import style from "./Userbar.module.scss"

const { UserbarStyle, aboutUser, userEmail, logout, label } = style

export const Userbar = () => {
	const { setCurrentUser } = useCurrentUserStore()
	const { resetSessionStoragePagination } = usePaginationStore()
	const { data } = useUserProfile()

	useEffect(() => {
		setCurrentUser({
			id: data?.id,
			role: data?.role,
		})
	}, [data?.role, data?.id, setCurrentUser])

	const { clearToken } = useCredentialsStore()
	const destroyTokenMutation = useDestroyToken()
	const navigate = useNavigate()
	const { error } = useMessage()

	const name = data?.name + " " + data?.secondName.charAt(0).toUpperCase() + "."
	const email = data?.email

	const logoutHandle = () => {
		destroyTokenMutation.mutate(undefined, {
			onSuccess: () => {
				clearToken()
				navigate("/")
				resetSessionStoragePagination()
			},
			onError: (e) => {
				if (e instanceof AxiosError) {
					error("Something went wrong, please try again later")
				}
			},
		})
	}

	const dropdownItems: MenuProps["items"] = [
		{
			key: "1",
			label: (
				<div className={aboutUser}>
					<span className={label}>{name}</span>
					<span className={userEmail}>{email}</span>
				</div>
			),
		},
		{
			type: "divider",
		},
		{
			key: "2",
			label: <span className={label}>Change password</span>,
			disabled: true,
		},
		{
			key: "3",
			label: <span className={label}>Re-set 2FA</span>,
			disabled: true,
		},
		{
			type: "divider",
		},
		{
			key: "4",
			label: (
				<div className={logout}>
					<LogoutIcon />
					<span className={label}>Log out</span>
				</div>
			),
			onClick: logoutHandle,
		},
	]

	return (
		<>
			{data && (
				<Dropdown items={dropdownItems}>
					<div className={UserbarStyle}>
						<span className={name}>{name}</span>
						{/* <div className={placeHolder}>
						<span>{data?.name.charAt(0).toUpperCase()}</span>
					</div> */}
					</div>
				</Dropdown>
			)}
		</>
	)
}
