import { Table as TableAnt } from "antd"
import type { ColumnsType } from "antd/es/table"
import { TableObjectsProvider } from "components/providers/Table.provider"

type Props<ColumnsDataType> = {
	columns: ColumnsType<ColumnsDataType>
	data: ColumnsDataType[]
	className?: string
	scrollY: number
}

export const TableObjects = <ColumnsDataType extends object>({
	columns,
	data,
	className,
	scrollY,
}: Props<ColumnsDataType>) => {
	return (
		<TableObjectsProvider>
			<TableAnt className={className} columns={columns} dataSource={data} pagination={false} scroll={{ y: scrollY }} />
		</TableObjectsProvider>
	)
}
