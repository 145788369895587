import { SoftwareVersions } from "components/ui/SoftwareVersions/SoftwareVersions"
import { useDrawingVersions } from "hooks/drawings.hook"
import { useSearchParams } from "react-router-dom"
import { TDrawingStatusProps } from "types/drawer.type"
import style from "./Versions.module.scss"

const { versionsStyle } = style

export const Versions = ({ drawingStatus }: TDrawingStatusProps) => {
	const [searchParams] = useSearchParams()
	const drawingId = Number(searchParams.get("drawingID"))
	const processingId = Number(searchParams.get("processingID"))

	const {
		data: versions,
		isError: isVersionsError,
		isPending: isVersionsPending,
	} = useDrawingVersions(drawingId, processingId, drawingStatus)

	return (
		<div className={versionsStyle}>
			<SoftwareVersions versions={versions} isVersionsError={isVersionsError} isVersionsPending={isVersionsPending} />
		</div>
	)
}
