import { useMutation } from "@tanstack/react-query"
import processFilesService from "services/processFiles.service"
import type { TProcessing } from "types/drawer.type"

export const useProcessFiles = () => {
	return useMutation({
		mutationKey: ["processingFiles"],
		mutationFn: ({ id, version }: TProcessing) => processFilesService.processFile({ id, version }),
	})
}
