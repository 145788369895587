import { Select } from "antd"
import type { DefaultOptionType } from "antd/es/select"
import type { SelectProps } from "antd"

type Props = {
	variant?: "borderless" | "outlined" | "filled" | undefined
	options: DefaultOptionType[]
	placement?: SelectProps["placement"]
	value: string | number | null
	open?: boolean
	onChange: (e: string | number) => void
	onDropdownVisibleChange: (arg: boolean) => void
}

export const SelectBorderless = ({
	variant = "borderless",
	options,
	placement = "bottomRight",
	value,
	onChange,
	onDropdownVisibleChange,
}: Props) => {
	return (
		<Select
			variant={variant}
			options={options}
			placement={placement}
			value={value}
			onChange={onChange}
			onDropdownVisibleChange={onDropdownVisibleChange}
		/>
	)
}
