import { Table as TableAnt } from "antd"
import type { ColumnsType, TablePaginationConfig, TableProps } from "antd/es/table"
import { TableProvider } from "components/providers/Table.provider"
import { MouseEventHandler } from "react"

type Props<ColumnsDataType> = {
	className?: string
	columns: ColumnsType<ColumnsDataType>
	data: ColumnsDataType[]
	total?: number
	current?: number
	loading?: boolean
	onChange?: TableProps<ColumnsDataType>["onChange"]
	onRow?: (record: ColumnsDataType) => {
		onClick: MouseEventHandler<HTMLTableRowElement>
	}
	position?: TablePaginationConfig["position"]
}

export const Table = <ColumnsDataType extends object>({
	className = "",
	columns,
	data,
	total,
	current,
	onChange,
	position = ["bottomRight"],
	onRow,
	loading,
}: Props<ColumnsDataType>) => {
	return (
		<TableProvider>
			<TableAnt
				className={`Table ${className}`}
				columns={columns}
				dataSource={data}
				onRow={onRow}
				onChange={onChange}
				loading={loading}
				pagination={{
					position: position,
					total: total,
					current: current,
					showQuickJumper: true,
					showSizeChanger: false,
				}}
			/>
		</TableProvider>
	)
}
