import style from "./FoundItemsSingleObject.module.scss"

const { foundItemsSingleObject, subItemName, totalNumber } = style

type Props = {
	subItem: string
	subValue: number
}

export const FoundItemsSingleObject = ({ subItem, subValue }: Props) => {
	return (
		<li className={foundItemsSingleObject} key={subItem}>
			<span className={subItemName}>{subItem}</span>
			<span className={totalNumber}>{subValue}</span>
		</li>
	)
}
