import { ObjectLeftIcon } from "components/ui/icons/objectsTable/objectsType/ObjectLeftIcon"
import { ObjectRightIcon } from "components/ui/icons/objectsTable/objectsType/ObjectRightIcon"
import { ObjectShelvesIcon } from "components/ui/icons/objectsTable/objectsType/ObjectShelvesIcon"
import { ObjectWholeIcon } from "components/ui/icons/objectsTable/objectsType/ObjectWholeIcon"
import type { TObjectsType } from "types/configuration.type"

export const ConfigurationsObjectsType = ({ type }: TObjectsType) => {
	switch (type) {
		case "left_part_of_dual_object":
			return (
				<div data-testid="leftIcon">
					<ObjectLeftIcon />
				</div>
			)
		case "right_part_of_dual_object":
			return (
				<div data-testid="rightIcon">
					<ObjectRightIcon />
				</div>
			)
		case "whole_dual_object":
			return (
				<div data-testid="wholeIcon">
					<ObjectWholeIcon />
				</div>
			)
		case "shelves_object":
			return (
				<div data-testid="shelvesIcon">
					<ObjectShelvesIcon />
				</div>
			)
	}
}
