import { ReactNode, MouseEvent } from "react"
import { Tag as AntTag } from "antd"
import { TagProvider } from "components/providers/Tag.provider"

type Props = {
	children: ReactNode
	bordered?: boolean
	closeIcon: ReactNode
	onClose: (e: MouseEvent<HTMLElement>) => void | undefined
}

export const Tag = ({ children, bordered = false, closeIcon, onClose }: Props) => {
	return (
		<TagProvider>
			<AntTag bordered={bordered} closeIcon={closeIcon} onClose={onClose}>
				{children}
			</AntTag>
		</TagProvider>
	)
}
