import { axiosInstance } from "api/axios.instance"
import { TProcessing } from "types/drawer.type"

class ProcessFilesService {
	async processFile({ id, version }: TProcessing) {
		return axiosInstance.post(`drawings/${id}/process`, {
			front_end_sw_version: version,
		})
	}
}

export default new ProcessFilesService()
