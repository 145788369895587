import { axiosInstance, axiosUploadFileInstance } from "api/axios.instance"
import type { TUploadFile, TUploadFinished } from "types/uploadFiles.type"

const uploadAxiosInstance = axiosUploadFileInstance()

class UploadFilesService {
	static BASE_URL = "drawings/upload"

	async uploadFile({ presignedUrl, file, onProgress, abortRequestSignal }: TUploadFile) {
		return uploadAxiosInstance.put(presignedUrl, file, {
			onUploadProgress: onProgress,
			signal: abortRequestSignal,
			headers: { "Content-Type": "application/pdf", "Content-Disposition": "attachment" },
		})
	}

	async uploadFileHash(hash: string, fileName: string) {
		return axiosInstance.post(`${UploadFilesService.BASE_URL}/order`, {
			PDF_MD5: hash,
			file_name: fileName,
		})
	}

	async uploadFinished({ uploadId, result, reason }: TUploadFinished) {
		return axiosInstance.patch(`${UploadFilesService.BASE_URL}/${uploadId}/finished`, {
			result: result,
			reason: reason,
		})
	}
}

export default new UploadFilesService()
