import type { TButtonDefault } from "types/buttons.type"
import style from "./ButtonWarning.module.scss"

const { button } = style

export const ButtonWarning = ({ children, className = "", onClick }: TButtonDefault) => {
	return (
		<button onClick={onClick} className={`${button} ${className}`}>
			{children}
		</button>
	)
}
