import { Input } from "antd"
import type { TOnChangeInput, TOnKeyDown } from "types/default.type"
import { InputNumberBorderlessProvider } from "components/providers/Input.provider"

type Props = {
	inputChangeHandle: (e: TOnChangeInput) => void
	onConfirmHandle: () => void
	value: string
	placeholder: string
	onKeyDown?: (e: TOnKeyDown) => void
}

export const InputBorderless = ({ inputChangeHandle, onConfirmHandle, onKeyDown, value, placeholder }: Props) => {
	return (
		<InputNumberBorderlessProvider>
			<Input
				onChange={inputChangeHandle}
				onPressEnter={onConfirmHandle}
				onKeyDown={onKeyDown}
				type="text"
				size="large"
				placeholder={placeholder}
				value={value}
				variant="borderless"
			/>
		</InputNumberBorderlessProvider>
	)
}
