import { create } from "zustand"
import Cookies from "js-cookie"
import type { TCredentials, TRegistration, TRegistrationSteps } from "types/auth.type"

export const useCredentialsStore = create<TCredentials>((set) => ({
	token: Cookies.get("token") || null,
	setCredentials: (token: string) => {
		Cookies.set("token", token)
		set(() => ({ token }))
	},
	clearToken: () => {
		Cookies.remove("token")
		set(() => ({ token: null }))
	},
}))

export const useRegistrationStore = create<TRegistration>((set) => ({
	data: {
		email: "",
		firstName: "",
		lastName: "",
		password: "",
		confirmPassword: "",
		token: "",
	},
	setRegistration: (newData) =>
		set((state) => ({
			data: {
				...state.data,
				...newData,
			},
		})),
}))

export const useRegistrationStepsStore = create<TRegistrationSteps>((set) => ({
	step: 1,
	setStep: (step) => set({ step: step }),
}))
