import { ConfigProvider } from "antd"

type Props = {
	children: React.ReactNode
}

export const InputSearchProvider = ({ children }: Props) => {
	return (
		<ConfigProvider
			theme={{
				token: {
					borderRadius: 12,
					colorBorder: "#CCD1DD",
				},
			}}
		>
			{children}
		</ConfigProvider>
	)
}
