import { UploadbarAllFiles } from "./layout/UploadbarAllFiles/UploadbarAllFiles"
// import { UploadbarFooter } from "./layout/UploadbarFooter/UploadbarFooter"
import { UploadbarHeader } from "./layout/UploadbarHeader/UploadbarHeader"
import style from "./Uploadbar.module.scss"

const { uploadBar, container, wrapper, block } = style

export const Uploadbar = () => {
	return (
		<div className={uploadBar} data-testid="uploadBar">
			<div className={container}>
				<div className={wrapper}>
					<div className={block}>
						<UploadbarHeader />
						<UploadbarAllFiles />
					</div>
					{/* <UploadbarFooter /> */}
				</div>
			</div>
		</div>
	)
}
