export const ReprocessIcon = () => {
	return (
		<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
				stroke="black"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6.00781 10.882C6.14281 11.107 6.30781 11.317 6.49531 11.5045C7.87531 12.8845 10.1178 12.8845 11.5053 11.5045C12.0678 10.942 12.3903 10.2295 12.4953 9.50195"
				stroke="black"
				strokeWidth="1.3"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.50586 8.49847C5.61086 7.76347 5.93336 7.05844 6.49586 6.49594C7.87586 5.11594 10.1184 5.11594 11.5059 6.49594C11.7009 6.69094 11.8584 6.90095 11.9934 7.11845"
				stroke="black"
				strokeWidth="1.3"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.86523 12.8853V10.8828H7.86772"
				stroke="black"
				strokeWidth="1.3"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.1353 5.11523V7.11772H10.1328"
				stroke="black"
				strokeWidth="1.3"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
