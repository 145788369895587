import { ReactNode } from "react"
import { Dropdown as AntDropdown } from "antd"
import type { MenuProps, DropDownProps } from "antd"

type Props = {
	className?: string
	items: MenuProps["items"]
	children: ReactNode
	onClick?: MenuProps["onClick"]
	open?: boolean
	onOpenChange?: DropDownProps["onOpenChange"]
	placement?: DropDownProps["placement"]
}

export const Dropdown = ({
	className = "",
	items,
	children,
	placement = "bottomRight",
	onClick,
	open,
	onOpenChange,
}: Props) => {
	return (
		<AntDropdown
			className={className}
			menu={{ items, onClick }}
			placement={placement}
			open={open}
			onOpenChange={onOpenChange}
		>
			{children}
		</AntDropdown>
	)
}
