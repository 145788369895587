import { useEffect } from "react"
import { NatsService } from "services/nats.service"
import { Subscription } from "nats.ws"
import type { IProgressMessage, IStatusMessage } from "types/nats.type"
import { useMessagesDataStore } from "store/nats.store"
import { useMessage } from "./message.hook"

export const useNats = (serverUrl: string, progressTopic: string, statusTopic: string) => {
	const { setMessagesData } = useMessagesDataStore()
	const { error: userErrorMessage } = useMessage()

	useEffect(() => {
		const natsService = new NatsService()

		let progressSub: Subscription | undefined
		let statusSub: Subscription | undefined

		const connectAndSubscribe = async () => {
			try {
				await natsService.connect(serverUrl)

				progressSub = natsService.subscribe<IProgressMessage>(progressTopic, (message, subject) => {
					const id = subject.split(".")[3]
					const idAsNumber = parseInt(id, 10)
					setMessagesData(idAsNumber, { progress: message.progress })
				})

				statusSub = natsService.subscribe<IStatusMessage>(statusTopic, (message, subject) => {
					const id = subject.split(".")[3]
					const idAsNumber = parseInt(id, 10)
					setMessagesData(idAsNumber, { status: message.status })
				})
			} catch (e) {
				// Show error message to user
				const error = e as Error
				if (error) userErrorMessage("Something went wrong, please try again later")
			}
		}

		connectAndSubscribe()

		return () => {
			// Clean up: unsubscribe and disconnect when the component unmounts
			progressSub?.unsubscribe()
			statusSub?.unsubscribe()
			natsService.close().catch((e) => console.error("Error closing NATS connection:", e))
		}
	}, [serverUrl, progressTopic, statusTopic, setMessagesData, userErrorMessage])
}
