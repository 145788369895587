export const EyeIcon = () => {
	return (
		<svg width="41" height="30" viewBox="0 0 41 30" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g opacity="0.3">
				<path
					d="M20.5 10.3125C17.9112 10.3125 15.8125 12.4112 15.8125 15C15.8125 17.5888 17.9112 19.6875 20.5 19.6875C21.9176 19.6875 23.1883 19.0582 24.0478 18.0637C22.6183 17.7766 21.5417 16.514 21.5417 15C21.5417 13.486 22.6183 12.2234 24.0478 11.9363C23.1883 10.9418 21.9176 10.3125 20.5 10.3125Z"
					fill="#0047FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M20.4999 0.9375C11.9975 0.9375 4.60098 5.66013 0.796995 12.6158C-0.0156518 14.1017 -0.0156658 15.898 0.796958 17.3839C4.6009 24.3397 11.9975 29.0625 20.5001 29.0625C29.0025 29.0625 36.399 24.3399 40.203 17.3842C41.0157 15.8983 41.0157 14.102 40.203 12.6161C36.3991 5.66028 29.0025 0.9375 20.4999 0.9375ZM12.6875 15C12.6875 10.6853 16.1853 7.1875 20.5 7.1875C24.8147 7.1875 28.3125 10.6853 28.3125 15C28.3125 19.3147 24.8147 22.8125 20.5 22.8125C16.1853 22.8125 12.6875 19.3147 12.6875 15Z"
					fill="#0047FF"
				/>
			</g>
			<path
				d="M20.5 10.3125C17.9112 10.3125 15.8125 12.4112 15.8125 15C15.8125 17.5888 17.9112 19.6875 20.5 19.6875C21.9176 19.6875 23.1883 19.0582 24.0478 18.0637C22.6183 17.7766 21.5417 16.514 21.5417 15C21.5417 13.486 22.6183 12.2234 24.0478 11.9363C23.1883 10.9418 21.9176 10.3125 20.5 10.3125Z"
				fill="#0047FF"
			/>
		</svg>
	)
}
