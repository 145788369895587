export const AdminIcon = () => {
	return (
		<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M10.2967 2.63297L11.6167 5.27297C11.7967 5.64047 12.2767 5.99297 12.6817 6.06047L15.0742 6.45797C16.6042 6.71297 16.9642 7.82297 15.8617 8.91797L14.0017 10.778C13.6867 11.093 13.5142 11.7005 13.6117 12.1355L14.1442 14.438C14.5642 16.2605 13.5967 16.9655 11.9842 16.013L9.7417 14.6855C9.3367 14.4455 8.6692 14.4455 8.2567 14.6855L6.0142 16.013C4.4092 16.9655 3.4342 16.253 3.8542 14.438L4.3867 12.1355C4.4842 11.7005 4.3117 11.093 3.9967 10.778L2.1367 8.91797C1.0417 7.82297 1.3942 6.71297 2.9242 6.45797L5.3167 6.06047C5.7142 5.99297 6.1942 5.64047 6.3742 5.27297L7.6942 2.63297C8.4142 1.20047 9.5842 1.20047 10.2967 2.63297Z"
				stroke="#0047FF"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
