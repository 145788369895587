import style from "./Logo.module.scss"

const { logo } = style

type Props = {
	className?: string
}

export const Logo = ({ className }: Props) => {
	return <span className={`${logo} ${className}`}>Stevens</span>
}
