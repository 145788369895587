export const LogoutIcon = () => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M12.5859 6.30312C12.3276 3.30312 10.7859 2.07812 7.41094 2.07812H7.3026C3.5776 2.07812 2.08594 3.56979 2.08594 7.29479V12.7281C2.08594 16.4531 3.5776 17.9448 7.3026 17.9448H7.41094C10.7609 17.9448 12.3026 16.7365 12.5776 13.7865"
				stroke="black"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path d="M7.50104 10H16.9844" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
			<path
				d="M15.1224 7.21094L17.9141 10.0026L15.1224 12.7943"
				stroke="black"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
