import { MenuProps } from "antd"
import { PencilIcon } from "components/ui/icons/objectsTable/dropdown/PencilIcon"
import { useModelNumbersModalStore } from "store/modal.store"
import style from "./Categories.module.scss"

const { editModelNumbers } = style

export const CategoriesUtils = () => {
	const { setModelNumbers, trigerModelNumbersModal, setObjectName, setCategoryId, setInitialModelNumbers } =
		useModelNumbersModalStore()

	const getDropDownItems = (modelNumbers: string[], objectName: string, categoryId: number) => {
		const items: MenuProps["items"] = [
			{
				key: "1",
				label: (
					<div className={editModelNumbers}>
						<PencilIcon />
						<span>Edit model numbers</span>
					</div>
				),
				onClick: () => {
					setModelNumbers(modelNumbers)
					setInitialModelNumbers(modelNumbers)
					setObjectName(objectName)
					setCategoryId(categoryId)
					trigerModelNumbersModal()
				},
			},
		]
		return items
	}

	return { getDropDownItems }
}
