export const DateIcon = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M16.0505 18.0898C15.596 18.0798 15.2305 17.6998 15.2305 17.2398C15.2206 16.7798 15.5862 16.4008 16.0406 16.3908H16.0505C16.5148 16.3908 16.8902 16.7708 16.8902 17.2398C16.8902 17.7098 16.5148 18.0898 16.0505 18.0898ZM12.0198 14.4098C11.5653 14.4308 11.1899 14.0698 11.1701 13.6108C11.1701 13.1508 11.5258 12.7708 11.9802 12.7498C12.4248 12.7498 12.7903 13.1008 12.8002 13.5498C12.82 14.0108 12.4643 14.3908 12.0198 14.4098ZM12.0198 18.0398C11.5653 18.0608 11.1899 17.6998 11.1701 17.2398C11.1701 16.7798 11.5258 16.4008 11.9802 16.3798C12.4248 16.3798 12.7903 16.7308 12.8002 17.1808C12.82 17.6408 12.4643 18.0208 12.0198 18.0398ZM7.95939 14.4098C7.50494 14.4308 7.12953 14.0698 7.10977 13.6108C7.10977 13.1508 7.46542 12.7708 7.91987 12.7498C8.36443 12.7498 8.72997 13.1008 8.73985 13.5498C8.7596 14.0108 8.40395 14.3908 7.95939 14.4098ZM7.94951 18.0398C7.49506 18.0608 7.11965 17.6998 7.09989 17.2398C7.09989 16.7798 7.45554 16.4008 7.90999 16.3798C8.35456 16.3798 8.72009 16.7308 8.72997 17.1808C8.74973 17.6408 8.39407 18.0208 7.94951 18.0398ZM15.2404 13.6008C15.2404 13.1408 15.596 12.7708 16.0505 12.7608C16.4951 12.7608 16.8507 13.1198 16.8705 13.5608C16.8804 14.0208 16.5247 14.4008 16.0801 14.4098C15.6257 14.4198 15.2503 14.0698 15.2404 13.6108V13.6008ZM3 10.2568V16.8698C3 20.0298 4.99561 21.9998 8.12733 21.9998H15.8628C19.0241 21.9998 21 20.0698 21 16.9308V10.2568H3Z"
				fill="#B3C8FF"
			/>
			<path
				d="M3.00391 9.25675C3.01675 8.66975 3.06615 7.50475 3.15901 7.12975C3.63321 5.02075 5.24353 3.68075 7.5454 3.48975H16.4565C18.7386 3.69075 20.3687 5.03975 20.8429 7.12975C20.9348 7.49475 20.9841 8.66875 20.997 9.25675H3.00391Z"
				fill="#0047FF"
			/>
			<path
				d="M8.30367 6.59C8.73836 6.59 9.06437 6.261 9.06437 5.82V2.771C9.06437 2.33 8.73836 2 8.30367 2C7.86898 2 7.54297 2.33 7.54297 2.771V5.82C7.54297 6.261 7.86898 6.59 8.30367 6.59Z"
				fill="#B3C8FF"
			/>
			<path
				d="M15.6943 6.59C16.1191 6.59 16.455 6.261 16.455 5.82V2.771C16.455 2.33 16.1191 2 15.6943 2C15.2596 2 14.9336 2.33 14.9336 2.771V5.82C14.9336 6.261 15.2596 6.59 15.6943 6.59Z"
				fill="#B3C8FF"
			/>
		</svg>
	)
}
