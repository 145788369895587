import { FirstScreen } from "components/ui/FirstScreen/FirstScreen"
import { useActivateUser } from "hooks/auth.hook"
import { useEffect, useState } from "react"
import { SuccessfullCheck } from "./layout/SuccessfullCheck/SuccessfullCheck"
import { ValidationCheck } from "../../ui/ValidationCheck/ValidationCheck"
import { useSearchParams } from "react-router-dom"
import { Spin } from "components/ui/spin/Spin"
import style from "./PageCheckAccount.module.scss"

const { pageCheckAccount } = style

export const PageCheckAccount = () => {
	const [isSuccessful, setIsSuccessful] = useState<boolean>(true)

	const [searchParams, setSearchParams] = useSearchParams()
	const id = searchParams.get("uid")
	const token = searchParams.get("token")

	const { mutate, isPending } = useActivateUser()

	// This useEffect emulates backend behavior, will be removed during the implementation of the real backend.
	useEffect(() => {
		if (!id || !token) {
			setSearchParams({ token: "string", uid: "string" })
		}
	}, [id, token, setSearchParams])

	useEffect(() => {
		if (id && token) {
			mutate(
				{ id, token },
				{
					onSuccess: () => {
						setIsSuccessful(true)
					},
					onError: () => {
						setIsSuccessful(false)
					},
				},
			)
		}
	}, [id, token, mutate])

	if (isPending) {
		return (
			<FirstScreen>
				<div className={pageCheckAccount} data-testid="spinner">
					<Spin />
				</div>
			</FirstScreen>
		)
	}

	return (
		<FirstScreen>
			<div className={pageCheckAccount}>
				{isSuccessful ? (
					<SuccessfullCheck />
				) : (
					<ValidationCheck
						message="Unfortunately, your account could not be activated."
						email="mailto: someemail@some.com"
					/>
				)}
			</div>
		</FirstScreen>
	)
}
