import { UploadedIcon } from "components/ui/icons/uploadbar/UploadedIcon"
import { UploadbarProgress } from "../UploadbarProgress/UploadbarProgress"
import { CanceledUploadIcon } from "components/ui/icons/uploadbar/CanceledUploadIcon"
import { FailedUpload } from "components/ui/icons/uploadbar/FailedUpload"
import { TFileUploadingDataStatus } from "types/uploadFiles.type"

type Props = {
	file: TFileUploadingDataStatus
}

export const UploadbarStatus = ({ file }: Props) => {
	switch (file.status) {
		case "uploaded":
			return <UploadedIcon />
		case "uploading":
			return <UploadbarProgress progress={file.progress} />
		case "canceled":
			return <CanceledUploadIcon />
		case "failed":
			return <FailedUpload />
	}
}
