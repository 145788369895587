import { axiosInstance } from "api/axios.instance"

class ObjectsCategoriesClass {
	static BASE_URL = "/categories"

	async getObjectsCategories() {
		return axiosInstance.get(`${ObjectsCategoriesClass.BASE_URL}/`)
	}

	async includeInProcessing(isIncludedInProcessing: boolean, categoryId: number) {
		return axiosInstance.patch(`${ObjectsCategoriesClass.BASE_URL}/${categoryId}/usedInProcessingStatus`, {
			status: isIncludedInProcessing,
		})
	}

	async updateModelNumbers(modelNumbers: string[], categoryId: number | null) {
		return axiosInstance.patch(`${ObjectsCategoriesClass.BASE_URL}/${categoryId}/modelNumbers`, {
			model_numbers: modelNumbers,
		})
	}
}

export default new ObjectsCategoriesClass()
