import { AdminIcon } from "components/ui/icons/userRoles/AdminIcon"
import { ExternalUserIcon } from "components/ui/icons/userRoles/ExternalUserIcon"
import { InternalUserIcon } from "components/ui/icons/userRoles/InternalUserIcon"
import { SuperAdminIcon } from "components/ui/icons/userRoles/SuperAdminIcon"
import style from "./BandgeUserRole.module.scss"

const { badge, notExtrernalUser, externalUser, label } = style

type Props = {
	role: string
	className?: string
}

export const BandgeUserRole = ({ role, className }: Props) => {
	switch (role) {
		case "Super Admin":
			return (
				<div className={`${badge} ${notExtrernalUser} ${className}`}>
					<SuperAdminIcon />
					<span className={label}>S-Admin</span>
				</div>
			)
		case "Admin":
			return (
				<div className={`${badge} ${notExtrernalUser} ${className}`}>
					<AdminIcon />
					<span className={label}>Admin</span>
				</div>
			)
		case "Internal User":
			return (
				<div className={`${badge} ${notExtrernalUser} ${className}`}>
					<InternalUserIcon />
					<span className={label}>Internal User</span>
				</div>
			)
		case "External User":
			return (
				<div className={`${badge} ${externalUser} ${className}`}>
					<ExternalUserIcon />
					<span className={label}>External</span>
				</div>
			)
		default:
			return null
	}
}
