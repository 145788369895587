import type { TButtonDefault } from "types/buttons.type"
import style from "./ButtonPrimary.module.scss"

const { button } = style

export const ButtonPrimary = ({ children, className = "", submittable = true, onClick }: TButtonDefault) => {
	return (
		<button onClick={onClick} className={`${button} ${className}`} disabled={!submittable}>
			{children}
		</button>
	)
}
