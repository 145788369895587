import { BrowserRouter as Router } from "react-router-dom"
import { useRoutes } from "./routes"
import { useCredentialsStore } from "store/auth.store"
import { ToastContainer } from "react-toastify"
import "./assets/scss/style.scss"

export const App = () => {
	const { token } = useCredentialsStore()
	const isAuthenticated = !!token
	const routes = useRoutes(isAuthenticated)

	return (
		<>
			<Router>{routes}</Router>
			<ToastContainer />
		</>
	)
}
