import { forwardRef } from "react"
import type { TButtonDefault } from "types/buttons.type"
import style from "./ButtonSecondary.module.scss"

const { button } = style

export const ButtonSecondary = ({ children, className = "", onClick }: TButtonDefault) => {
	return (
		<button onClick={onClick} className={`${button} ${className}`}>
			{children}
		</button>
	)
}

export const ButtonSecondaryForwardRef = forwardRef<HTMLButtonElement, TButtonDefault>(
	({ children, className = "", submittable = true, onClick, ...props }, ref) => {
		return (
			<button ref={ref} onClick={onClick} className={`${button} ${className}`} disabled={!submittable} {...props}>
				{children}
			</button>
		)
	},
)
