import { axiosInstance } from "api/axios.instance"

class UserService {
	static BASE_URL = "users"

	async getUserProfile() {
		return axiosInstance.get(`${UserService.BASE_URL}/profile`)
	}

	async getUsers() {
		return axiosInstance.get(UserService.BASE_URL)
	}

	async changeUserRole(id: number, newRole: string) {
		return axiosInstance.post(`${UserService.BASE_URL}/${id}/role`, { newRole })
	}

	async inviteUser() {
		return axiosInstance.post("registration/token")
	}
}

export default new UserService()
