import { Spin as SpinAnt } from "antd"
// import { SizeType } from "antd/es/config-provider/SizeContext"
import { SpinSize } from "antd/es/spin"
type Props = {
	className?: string
	size?: SpinSize
}

export const Spin = ({ className, size }: Props) => {
	return <SpinAnt className={className} size={size} />
}
