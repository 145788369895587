import { axiosInstance } from "api/axios.instance"

class KeywordsService {
	static BASE_URL = "/keywords"

	async getKeywords() {
		return axiosInstance.get(`${KeywordsService.BASE_URL}/`)
	}

	async deleteKeyword(id: number) {
		return axiosInstance.delete(`${KeywordsService.BASE_URL}/${id}`)
	}

	async addKeyword(keyword: string, colorId: number | null) {
		return axiosInstance.post(`${KeywordsService.BASE_URL}/`, {
			keyword: keyword,
			colorId: colorId,
		})
	}

	async getKeywordsColors() {
		return axiosInstance.get(`${KeywordsService.BASE_URL}/colors`)
	}
}

export default new KeywordsService()
