import { Drawer as AntDrawer } from "antd"
import { DrawerProvider } from "components/providers/Drawer.provider"
import { ReactNode } from "react"
import type { TCloseDrawer } from "types/default.type"

type Props = {
	width: number
	children: ReactNode
	open: boolean
	onClose: (e: TCloseDrawer) => void
	closeIcon?: boolean
}

export const Drawer = ({ width = 800, children, open, onClose, closeIcon = false }: Props) => {
	return (
		<DrawerProvider>
			<AntDrawer width={width} open={open} onClose={onClose} closeIcon={closeIcon}>
				{children}
			</AntDrawer>
		</DrawerProvider>
	)
}
