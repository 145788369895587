import { useEffect, useState } from "react"
import { ArrowIcon } from "components/ui/icons/uploadbar/ArrowIcon"
import { CloseIcon } from "components/ui/icons/uploadbar/CloseIcon"
import { useUploadFilesDataStore, useUploadbarEnabledStore, useUploadbarOpenStore } from "store/uploadFiles.store"
import { useFilesToAbortStore } from "store/uploadFiles.store"
import { Modal } from "components/ui/modal/Modal"
import { ButtonPrimary } from "components/ui/buttons/ButtonPrimary/ButtonPrimary"
import { useMessage } from "hooks/message.hook"
import { ButtonWarning } from "components/ui/buttons/ButtonWarning/ButtonWarning"
import style from "./UploadbarHeader.module.scss"

const { uploadbarHeader, title, iconsBlock, close, buttons, modal } = style

export const UploadbarHeader = () => {
	const { triggerOpen, isOpen } = useUploadbarOpenStore()
	const { setUploadbarDisabled } = useUploadbarEnabledStore()
	const { setClearFilesData, files } = useUploadFilesDataStore()
	const { abortAllFiles } = useFilesToAbortStore()

	const { success } = useMessage()
	const [modalClose, setModalClose] = useState(false)

	const [filesCount, setFilesCount] = useState<null | number>(null)

	const minimizeToggleHandle = () => {
		triggerOpen()
	}

	const closeHandle = () => {
		const uploadedCount = Object.values(files).reduce((count, { status }) => {
			return status === "uploading" ? ++count : count
		}, 0)

		if (uploadedCount > 0) {
			setModalClose(true)
		} else {
			setUploadbarDisabled()
			setClearFilesData()
		}
	}

	const cancelUplodingHandle = () => {
		abortAllFiles()
		setClearFilesData()
		setModalClose(true)
		setUploadbarDisabled() // Disable the upload bar
		success("Uploading canceled")
	}

	useEffect(() => {
		setFilesCount(files.length)
	}, [files])

	return (
		<>
			<div className={uploadbarHeader}>
				<span className={title}>
					Uploading {filesCount && filesCount > 0 ? filesCount : ""} {filesCount && filesCount > 1 ? "files" : "file"}
				</span>
				<div className={iconsBlock}>
					<button className={`${isOpen === false ? close : ""}`} onClick={minimizeToggleHandle}>
						<ArrowIcon />
					</button>
					<button onClick={closeHandle} data-testid="closeUploadBarButton">
						<CloseIcon />
					</button>
				</div>
			</div>
			<Modal
				title="Cancel uploading?"
				className={modal}
				isModalOpen={modalClose}
				setModalClose={() => setModalClose(false)}
			>
				<p>File upload is not complete. Cancel all ongoing uploads?</p>
				<div className={buttons}>
					<ButtonWarning onClick={cancelUplodingHandle}>Cancel uploading</ButtonWarning>
					<ButtonPrimary onClick={() => setModalClose(false)}>Continue uploading</ButtonPrimary>
				</div>
			</Modal>
		</>
	)
}
