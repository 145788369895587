export const PencilIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
			<path
				d="M7.94745 2.07931L1.78995 8.59681C1.55745 8.84431 1.33245 9.33181 1.28745 9.66931L1.00995 12.0993C0.912452 12.9768 1.54245 13.5768 2.41245 13.4268L4.82745 13.0143C5.16495 12.9543 5.63745 12.7068 5.86995 12.4518L12.0275 5.93431C13.0925 4.80931 13.5725 3.52681 11.915 1.95931C10.265 0.406813 9.01245 0.954313 7.94745 2.07931Z"
				stroke="black"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6.92188 3.16602C7.24437 5.23602 8.92437 6.81852 11.0094 7.02852"
				stroke="black"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
