import CryptoJS from "crypto-js"

export const getMD5Hash = (file: Blob) => {
	return new Promise<string>((resolve, reject) => {
		const chunkSize = 2 * 1024 * 1024 // 2MB chunk size
		let currentOffset = 0
		const fileReader = new FileReader()
		const md5Hash = CryptoJS.algo.MD5.create() // Create incremental MD5 hasher

		fileReader.onload = (e) => {
			if (e.target && e.target.result) {
				const arrayBuffer = e.target.result
				if (arrayBuffer instanceof ArrayBuffer) {
					const wordArray = CryptoJS.lib.WordArray.create(arrayBuffer)
					md5Hash.update(wordArray) // Update the hash with the chunk
					currentOffset += chunkSize

					if (currentOffset < file.size) {
						processNextChunk() // Process the next chunk
					} else {
						const finalHash = md5Hash.finalize().toString()
						resolve(finalHash) // When done, resolve the hash
					}
				} else {
					reject(new Error("FileReader did not read an ArrayBuffer as expected."))
				}
			} else {
				reject(new Error("FileReader did not emit a target or result."))
			}
		}

		fileReader.onerror = (e) => {
			reject(new Error("Error calculating MD5: " + (e.target?.error?.message || "Unknown error")))
		}

		// Function to read the next chunk of the file
		const processNextChunk = () => {
			const nextChunk = file.slice(currentOffset, currentOffset + chunkSize)
			fileReader.readAsArrayBuffer(nextChunk)
		}

		processNextChunk() // Start processing
	})
}
