import { useMutation, useQuery } from "@tanstack/react-query"
import keywordsService from "services/keywords.service"

export const useKeywords = () => {
	return useQuery({
		queryKey: ["keywords"],
		queryFn: () => keywordsService.getKeywords(),
		select: ({ data }) => data,
	})
}

export const useDeleteKeyword = () => {
	return useMutation({
		mutationFn: (id: number) => keywordsService.deleteKeyword(id),
	})
}

export const useAddKeyword = () => {
	return useMutation({
		mutationFn: ({ keyword, colorId }: { keyword: string; colorId: null | number }) =>
			keywordsService.addKeyword(keyword, colorId),
	})
}

export const useKeywordsColors = () => {
	return useQuery({
		queryKey: ["keywordsColors"],
		queryFn: () => keywordsService.getKeywordsColors(),
		select: ({ data }) => data.colors,
	})
}
