import style from "./ValidationBlock.module.scss"

const { validationBlock, item, indicator, descr, valid } = style

type Props = {
	validate: {
		lowercase: boolean
		numbers: boolean
		uppercase: boolean
		minimumCharacters: boolean
		specialCharacters: boolean
	}
}

export const ValidationBlock = ({ validate }: Props) => {
	return (
		<div className={validationBlock}>
			<div className={item}>
				<div className={validate.minimumCharacters === true ? `${indicator} ${valid}` : `${indicator}`}></div>
				<span className={descr}>8-32 characters</span>
			</div>
			<div className={item}>
				<div className={validate.numbers === true ? `${indicator} ${valid}` : `${indicator}`}></div>
				<span className={descr}>Numbers</span>
			</div>
			<div className={item}>
				<div className={validate.lowercase === true ? `${indicator} ${valid}` : `${indicator}`}></div>
				<span className={descr}>Lowercase characters</span>
			</div>
			<div className={item}>
				<div className={validate.specialCharacters === true ? `${indicator} ${valid}` : `${indicator}`}></div>
				<span className={descr}>Special characters</span>
			</div>
			<div className={item}>
				<div className={validate.uppercase === true ? `${indicator} ${valid}` : `${indicator}`}></div>
				<span className={descr}>Uppercase characters</span>
			</div>
		</div>
	)
}
