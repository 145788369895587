import { SidebarLeft } from "components/ui/SidebarLeft/SidebarLeft"
import { ConfigurationTabs } from "./layout/ConfigurationTabs/ConfigurationTabs"
import { ConfigurationContent } from "./layout/ConfigurationContent/ConfigurationContent"
import { useConfigurationTabsStore } from "store/configuration.store"
import style from "./PageConfiguration.module.scss"

const { pageConfiguration, content } = style

export const PageConfiguration = () => {
	const { configurationTabsIndex } = useConfigurationTabsStore()

	return (
		<section className={pageConfiguration}>
			<SidebarLeft>
				<ConfigurationTabs />
			</SidebarLeft>
			<div className={content}>
				<ConfigurationContent index={configurationTabsIndex} />
			</div>
		</section>
	)
}
