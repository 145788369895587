import { Space, TableColumnsType } from "antd"
import { Tooltip } from "components/ui/tooltip/Tooltip"
import { TObjectsConfigurationsTable } from "types/drawer.type"
import { ConfigurationsObjectsType } from "components/ui/ConfigurationsObjectsType/ConfigurationsObjectsType"
import { InModelTrueIcon } from "components/ui/icons/objectsTable/inModel/InModelTrueIcon"
import { InModelFalseIcon } from "components/ui/icons/objectsTable/inModel/InModelFalseIcon"
import { objectTypeTextTooltip } from "helpers/utils.helper"
import { Checkbox } from "components/ui/checkbox/Checkbox"
import { CategoriesHandlers } from "./CategoriesHandlers"
import { CategoriesUtils } from "./CategoriesUtils"
import { ButtonOptions } from "components/ui/buttons/ButtonOptions/ButtonOptions"
import { Dropdown } from "components/ui/dropdown/Dropdown"
import style from "./Categories.module.scss"

const { modelNumbers, objectType } = style

export const CategoriesColumns = () => {
	const { includeInProcessingHandle } = CategoriesHandlers()
	const { getDropDownItems } = CategoriesUtils()

	const tableColumns: TableColumnsType<TObjectsConfigurationsTable> = [
		{
			title: "NAME",
			dataIndex: "name",
			key: "name",
			width: 150,
		},
		{
			title: "",
			dataIndex: "type",
			key: "type",
			width: 50,
			render: (item) => {
				const getTooltipText = objectTypeTextTooltip(item)

				return (
					<Tooltip text={getTooltipText} placement="bottom">
						<div className={objectType} data-testid="objectType">
							<ConfigurationsObjectsType type={item} />
						</div>
					</Tooltip>
				)
			},
		},
		{
			title: "LABEL",
			dataIndex: "modelLable",
			key: "modelLable",
			width: 100,
		},
		{
			title: "MODEL NUMBERS",
			dataIndex: "modelNumbers",
			key: "modelNumbers",
			width: 200,
			render: (item) =>
				item.map((item: string) => (
					<span key={item} className={modelNumbers}>
						{item}
					</span>
				)),
		},
		{
			title: "IN MODEL",
			dataIndex: "includedInLatestModelTrain",
			key: "includedInLatestModelTrain",
			width: 60,
			render: (item) => {
				return item ? (
					<Tooltip placement="bottom" text="Included in the latest model train">
						<span data-testid="includedInLatestModelTrain">
							<InModelTrueIcon />
						</span>
					</Tooltip>
				) : (
					<Tooltip placement="bottom" text="Not included in the latest model train">
						<span data-testid="notTncludedInLatestModelTrain">
							<InModelFalseIcon />
						</span>
					</Tooltip>
				)
			},
		},
		{
			title: "OBJ. COUNT",
			dataIndex: "objectCount",
			key: "objectCount",
			width: 60,
		},
		{
			title: "PROCESSING",
			dataIndex: "includedInProcessingResult",
			key: "includedInProcessingResult",
			width: 70,
			render: (item) =>
				item.includedInLatestModelTrain ? (
					<div data-testid="checkbox">
						<Checkbox
							defaultChecked={item.includedInProcessingResult}
							onChange={(e) => includeInProcessingHandle(e.target.checked, item.categoryId)}
						/>
					</div>
				) : null,
		},
		{
			title: "",
			dataIndex: "action",
			key: "action",
			width: 50,
			render: (item) => (
				<div onClick={(e) => e.stopPropagation()}>
					<Dropdown items={getDropDownItems(item.modelNumbers, item.name, item.categoryId)}>
						<Space>
							<ButtonOptions />
						</Space>
					</Dropdown>
				</div>
			),
		},
	]

	return tableColumns
}
