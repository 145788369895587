export const DownloadIcon = ({ className = "" }) => {
	return (
		<svg
			className={className}
			width="29"
			height="28"
			viewBox="0 0 29 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M14.5 5.8335V16.9168M14.5 16.9168L11 13.4168M14.5 16.9168L18 13.4168"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6.91699 17.5V20.4167C6.91699 21.061 7.43933 21.5833 8.08366 21.5833H20.917C21.5613 21.5833 22.0837 21.061 22.0837 20.4167V17.5"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
