import { axiosInstance } from "api/axios.instance"

class DonwloadFileService {
	async getPresignedDownloadUrl(url: string) {
		return axiosInstance.get(url)
	}

	async fileDownload(url: string, fileName: string, type: string): Promise<void> {
		const link = document.createElement("a")
		link.href = url
		link.style.display = "none"
		link.setAttribute("download", `${fileName}.${type}`)
		document.body.appendChild(link)
		link.click()
		link.remove()
	}
}

export default new DonwloadFileService()
