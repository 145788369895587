import { ConfigProvider } from "antd"
import { ReactNode } from "react"

type props = {
	children: ReactNode
}

export const TabsDrawerProvider = ({ children }: props) => {
	return (
		<ConfigProvider
			theme={{
				components: {
					Tabs: {
						titleFontSizeLG: 24,
						cardBg: "rgb(242, 243, 247)",
						horizontalMargin: "0 28px 0 28px",
						itemSelectedColor: "#000",
					},
				},
				token: {
					colorBorder: "red",
					colorBorderSecondary: "transparent",
				},
			}}
		>
			{children}
		</ConfigProvider>
	)
}
