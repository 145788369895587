import { UserData } from "../UserData/UserData"
import { UserPassword } from "../UserPassword/UserPassword"
import { useRegistrationStepsStore } from "store/auth.store"
import { CheckEmail } from "../CheckEmail/CheckEmail"
import { useCheckRegisterToken } from "hooks/auth.hook"
import { useSearchParams } from "react-router-dom"
import { AxiosError } from "axios"
import { useEffect, useState } from "react"
import { ValidationCheck } from "components/ui/ValidationCheck/ValidationCheck"
import { Skeleton } from "components/ui/skeleton/Skeleton"
import style from "./FormRegistration.module.scss"

const { formRegistration } = style

export const FormRegistration = () => {
	const { step } = useRegistrationStepsStore()
	const [errorStatus, setErrorStatus] = useState<number | undefined>(undefined)

	const [searchParams] = useSearchParams()
	const token = searchParams.get("token")

	const { error: errorCheckRegistrationToken, isPending: isCheckRegistrationTokenPending } =
		useCheckRegisterToken(token)

	useEffect(() => {
		if (errorCheckRegistrationToken instanceof AxiosError) {
			const errorStatus = errorCheckRegistrationToken.response?.status
			setErrorStatus(errorStatus)
		}
	}, [errorCheckRegistrationToken])

	switch (step) {
		case 1:
			return (
				<div className={formRegistration} data-testid="userData">
					{errorStatus === 400 ? (
						<ValidationCheck
							message="Your invite link has expired. You can contact us at suppor@steavens.com and we'll get back to you soon."
							email="suppor@steavens.com"
						/>
					) : errorStatus !== 400 && token && errorStatus !== undefined ? (
						<ValidationCheck
							message="Something went wrong, please try again later. You can contact us at suppor@steavens.com and we'll get back to you soon."
							email="suppor@steavens.com"
						/>
					) : isCheckRegistrationTokenPending && token ? (
						<Skeleton />
					) : (
						<UserData />
					)}
				</div>
			)

		case 2:
			return (
				<div className={formRegistration} data-testid="userPassword">
					<UserPassword token={token} />
				</div>
			)

		case 3:
			return (
				<div className={formRegistration} data-testid="checkEmail">
					<CheckEmail />
				</div>
			)
	}
}
