import { firstMonthFormatDate } from "helpers/date"
import { Skeleton } from "../skeleton/Skeleton"
import style from "./SoftwareVersions.module.scss"

const { wrapper, item, version, instance } = style

type Props = {
	versions: {
		back_end_sw_version: string
		ml_sw_version: string
		model_version: string
		front_end_sw_version: string
	}
	isVersionsPending: boolean
	isVersionsError: boolean
	frontEndVersion?: string
}

export const SoftwareVersions = ({ versions, frontEndVersion, isVersionsPending, isVersionsError }: Props) => {
	return (
		<>
			{isVersionsPending ? (
				<Skeleton rows={2} />
			) : isVersionsError ? (
				<div className={wrapper}>
					<p>Error loading versions. Please try again later.</p>
				</div>
			) : (
				<div className={wrapper}>
					{versions.ml_sw_version ? (
						<div className={item}>
							<span className={version}>{versions.ml_sw_version}</span>
							<span className={instance}>ML Backend version</span>
						</div>
					) : null}
					{versions.back_end_sw_version ? (
						<div className={item}>
							<span className={version}>{versions.back_end_sw_version}</span>
							<span className={instance}>Backend version</span>
						</div>
					) : null}
					<div className={item}>
						<span className={version}>
							{versions.front_end_sw_version ? versions.front_end_sw_version : frontEndVersion}
						</span>
						<span className={instance}>Frontend version</span>
					</div>
					{versions.model_version ? (
						<div className={item}>
							<span className={version}>{firstMonthFormatDate(versions.model_version)}</span>
							<span className={instance}>Model version</span>
						</div>
					) : null}
				</div>
			)}
		</>
	)
}
