import { create } from "zustand"
import type { TCurrentUser } from "types/user.type"

export const useCurrentUserStore = create<TCurrentUser>((set) => ({
	currentUser: {
		id: null,
		role: "",
	},
	setCurrentUser: (user) => set({ currentUser: user }),
}))
