import { create } from "zustand"
import { TFileModal, TDeleteConfirmModal, TModelNumbersModal } from "types/modal.type"

export const useFileModalStore = create<TFileModal>((set) => ({
	isFileModalOpen: false,
	triggerFileModal: () => set((state) => ({ isFileModalOpen: !state.isFileModalOpen })),
	setFileModalClose: () => set(() => ({ isFileModalOpen: false })),
}))

export const useDeleteConfirmModalStore = create<TDeleteConfirmModal>((set) => ({
	isDeleteConfirmModalOpen: false,
	trigerDeleteConfirmModal: () => set((state) => ({ isDeleteConfirmModalOpen: !state.isDeleteConfirmModalOpen })),
	setDeleteConfirmDodalClose: () => set(() => ({ isDeleteConfirmModalOpen: false })),

	deleteFile: { id: 0, fileName: "" },
	setDeleteFile: (file) => set(() => ({ deleteFile: file })),
}))

export const useModelNumbersModalStore = create<TModelNumbersModal>((set) => ({
	modelNumbers: [""],
	setModelNumbers: (newModelNumbers) => set(() => ({ modelNumbers: newModelNumbers })),
	updateModelNumbers: (newModelNumbers) => set((state) => ({ modelNumbers: [...state.modelNumbers, newModelNumbers] })),
	clearModelNumbers: () => set(() => ({ modelNumbers: [""] })),

	isModelNumbersModalOpen: false,
	trigerModelNumbersModal: () => set((state) => ({ isModelNumbersModalOpen: !state.isModelNumbersModalOpen })),
	setModelNumbersModalClose: () => set(() => ({ isModelNumbersModalOpen: false })),

	objectName: "",
	setObjectName: (newObjectName) => set(() => ({ objectName: newObjectName })),

	categoryId: null,
	setCategoryId: (id) => set(() => ({ categoryId: id })),

	initialModelNumbers: [""],
	clearInitilaModelNumbers: () => set(() => ({ initialModelNumbers: [""] })),
	setInitialModelNumbers: (modelNumbers) => set(() => ({ initialModelNumbers: modelNumbers })),
}))
