export const SearchIcon = () => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle opacity="0.3" cx="8" cy="8" r="8" fill="#0047FF" />
			<path
				d="M15.0419 15.5L18.5 18.9578"
				stroke="#0047FF"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
