import { useEffect, useState } from "react"
// import { InputSearch } from "components/ui/inputs/InputSearch/InputSearch"
import { Keyword } from "components/pages/PageConfiguration/layout/Keywords/layout/Keyword/Keyword"
import { Skeleton } from "components/ui/skeleton/Skeleton"
import type { TDrawerKeywords } from "types/drawer.type"
import style from "./FileInfoKeywords.module.scss"

const { fileInfoKeywords, keywordsByAlphabet, letterBox, letter, keywordsBox, skeleton, message } = style

type Props = {
	drawingKeywords: TDrawerKeywords
	isKeywordsPending: boolean
	errorFetchingKewords: boolean
}

export const FileInfoKeywords = ({ drawingKeywords, isKeywordsPending, errorFetchingKewords }: Props) => {
	const keywords = drawingKeywords as TDrawerKeywords
	const [alphabet, setAlphabet] = useState<string[]>([])
	const [isKeywordsEmpty, setIsKeywordsEmpty] = useState<boolean>()

	// Set the keywords from API
	useEffect(() => {
		if (keywords) {
			const alphabet = Object.keys(keywords)
			setAlphabet(alphabet)
		}
	}, [keywords])

	// Check if Keywords empty
	useEffect(() => {
		if (alphabet) {
			const empty = Object.keys(alphabet).length === 0
			setIsKeywordsEmpty(empty)
		}
	}, [alphabet])

	// Display skeleton if request is pending
	if (isKeywordsPending) {
		return (
			<div className={skeleton} data-testid="keywordsSkeleton">
				<Skeleton rows={12} />
			</div>
		)
	}

	return (
		<div className={fileInfoKeywords}>
			{isKeywordsEmpty || errorFetchingKewords
				? ""
				: // <div className={search}>{ <InputSearch disabled={true} /> }</div>
					""}
			<div className={keywordsByAlphabet}>
				{isKeywordsEmpty && !errorFetchingKewords ? (
					<span className={message}>No keywords found</span>
				) : errorFetchingKewords ? (
					<span className={message}>Something went wrong, please try again later</span>
				) : (
					alphabet?.map((letterItem) => (
						<div className={letterBox} key={letterItem}>
							<span className={letter}>{letterItem.toUpperCase()}</span>
							<div className={keywordsBox}>
								{keywords[letterItem] &&
									Object.entries(keywords[letterItem]).map(([keywordName, keywordDetails]) => (
										<div key={keywordDetails.keywordId}>
											<Keyword
												style={{ backgroundColor: keywordDetails.keywordColor, color: keywordDetails.textColor }}
											>
												{keywordName}
											</Keyword>
										</div>
									))}
							</div>
						</div>
					))
				)}
			</div>
		</div>
	)
}
