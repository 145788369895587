import { ReactNode } from "react"
import { Logo } from "../logo/Logo"
import { H1 } from "../typography/titles/Titles"
import style from "./FirstScreen.module.scss"

const { firstScreen, wrapper, container, about, title, yellow, stevens, form, logo, img } = style

type Props = {
	children: ReactNode
}

export const FirstScreen = ({ children }: Props) => {
	return (
		<main className={firstScreen}>
			<div className={wrapper}>
				<div className={container}>
					<Logo className={logo} />
					<div className={form}>{children}</div>
				</div>
				<div className={about}>
					<span className={stevens}>Stevens Inc. AutoScan</span>
					<H1 className={title}>
						Find furniture <span className={yellow}>with ease</span>
					</H1>
					<div className={img}></div>
				</div>
			</div>
		</main>
	)
}
