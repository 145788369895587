import type { TBadge } from "types/badges.type"
import style from "./BadgeStatus.module.scss"

const { badgeStatus, processed, uploaded, processing, failed, enqueue } = style

type Props = {
	className?: string
	type: TBadge
}

export const BandgeStatus = ({ className = "", type }: Props) => {
	switch (type) {
		case "processed":
			return (
				<div className={`${badgeStatus} ${processed} ${className}`} data-testid="fileStatus">
					Processed
				</div>
			)
		case "uploaded":
			return (
				<div className={`${badgeStatus} ${uploaded} ${className}`} data-testid="fileStatus">
					Uploaded
				</div>
			)
		case "processing":
			return (
				<div className={`${badgeStatus} ${processing} ${className}`} data-testid="fileStatus">
					Processing
				</div>
			)
		case "failed":
			return (
				<div className={`${badgeStatus} ${failed} ${className}`} data-testid="fileStatus">
					Failed
				</div>
			)
		case "in_queue":
			return (
				<div className={`${badgeStatus} ${enqueue} ${className}`} data-testid="fileStatus">
					In queue
				</div>
			)
	}
}
