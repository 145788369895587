export const ObjectWholeIcon = () => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M7.24609 9.1416V10.8549"
				stroke="#0047FF"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.7539 9.1416V10.8549"
				stroke="#0047FF"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M17.2439 4.90649C17.2439 3.49649 16.1082 2.5 14.2853 2.5H9.87163L9.87109 17.4968H14.2843C16.1082 17.4968 17.2439 16.5011 17.2439 15.0911V4.90649Z"
				stroke="#0047FF"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M2.5 15.0911C2.5 16.5011 3.6336 17.4968 5.45763 17.4968H9.871L9.87154 2.5H5.45763C3.63989 2.5 2.5 3.49649 2.5 4.90649V15.0911Z"
				stroke="#0047FF"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
