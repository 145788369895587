import { ColumnsType } from "antd/es/table"
import { TeamTableDropdown } from "./TeamTableDropdown"
import type { IUsersTable } from "types/table.type"
import { BandgeUserRole } from "components/ui/bandges/BandgeUserRole/BandgeUserRole"

export const TeamTableColumns = () => {
	const columns: ColumnsType<IUsersTable> = [
		{
			title: "NAME",
			dataIndex: "name",
		},
		{
			title: "EMAIL",
			dataIndex: "email",
		},
		{
			title: "ROLE",
			dataIndex: "role",
			render: (item) => <BandgeUserRole role={item} />,
		},
		{
			title: "",
			dataIndex: "action",
			render: (item) => {
				return item.role === "Super Admin" ? null : (
					<TeamTableDropdown availableRoles={item.availableRoles} id={item.id} name={item.name} />
				)
			},
		},
	]

	return columns
}
