import { useDeleteDrawing, useDuplicateDrawing } from "hooks/drawings.hook"
import { useMessage } from "hooks/message.hook"
import { useProcessFiles } from "hooks/processFiles.hook"
import { useDeleteConfirmModalStore } from "store/modal.store"
import { useMessagesDataStore } from "store/nats.store"
import { usePaginationStore } from "store/table.store"
import { useSearchParams } from "react-router-dom"
import { AxiosError } from "axios"
import type { TStopPropagation } from "types/default.type"
import type { TTable } from "types/table.type"
import type { TableProps } from "antd/es/table"

const version = import.meta.env.VITE_APP_VERSION

export const TableAllFilesHandlers = (refetchDrawings?: () => void) => {
	const { mutate: processFilesMutate } = useProcessFiles()
	const { mutate: duplicateDrawingMutate } = useDuplicateDrawing()
	const { error: userErrorMessage, success: userSuccessMessage } = useMessage()
	const { mutate: deleteDrawing } = useDeleteDrawing()
	const [, setSearchParams] = useSearchParams()

	const { setMessagesData } = useMessagesDataStore()
	const { setDeleteConfirmDodalClose, deleteFile } = useDeleteConfirmModalStore()
	const { setCurrentPage, setFilter } = usePaginationStore()

	// Start processing handle
	const startProcessingHandle = (id: number, e?: TStopPropagation) => {
		if (e) e.stopPropagation()

		processFilesMutate(
			{ id, version },
			{
				onSuccess: () => {
					setMessagesData(id, {
						status: "in_queue",
						progress: 0,
					})
				},
				onError: () => {
					userErrorMessage("Something went wrong, please try again later")
					setMessagesData(id, {
						status: "failed",
					})
				},
			},
		)
	}

	// Handle row click for opening the drawer
	const onRowHandle = (record: TTable) => ({
		onClick: () => {
			const recordId = record.id.toString()
			setSearchParams({ drawingID: recordId })
		},
		onMouseEnter: () => {
			document.body.style.cursor = "pointer"
		},
		onMouseLeave: () => {
			document.body.style.cursor = "default"
		},
	})

	// Delete the file on delete confirmation
	const deleteFileHandle = () => {
		deleteDrawing(deleteFile.id, {
			onSuccess: () => {
				if (refetchDrawings) {
					refetchDrawings()
					userSuccessMessage(`${deleteFile.fileName} is succesfully deleted`)
					setDeleteConfirmDodalClose()
				}
			},
			onError: () => {
				userErrorMessage("Something went wrong, please try again later")
				setDeleteConfirmDodalClose()
			},
		})
	}

	const duplicateDrawingHandle = (id: number) => {
		duplicateDrawingMutate(id, {
			onSuccess: () => {
				if (refetchDrawings) {
					refetchDrawings()
				}
				userSuccessMessage("File duplicated successfully")
			},
			onError: (error) => {
				if (error instanceof AxiosError) {
					userErrorMessage("Something went wrong, please try again later")
				}
			},
		})
	}

	const onChangeTableHandle: TableProps<TTable>["onChange"] = (pagination, filters, _sorter, extra) => {
		const author = filters.author && filters.author[0]
		const currentPage = pagination.current!
		const onChangeAction = extra.action

		switch (onChangeAction) {
			case "paginate":
				setCurrentPage(currentPage)
				break

			case "filter":
				if (author === null) {
					// Handle null case: possibly reset the filter
					setFilter("all")
				} else if (typeof author === "string") {
					setFilter(author)
				}
				break
		}
	}

	return { startProcessingHandle, onRowHandle, deleteFileHandle, onChangeTableHandle, duplicateDrawingHandle }
}
