// store/useSubscriptionStore.ts
import { create } from "zustand"
import { IMessagesData } from "types/nats.type"

export const useMessagesDataStore = create<IMessagesData>((set) => ({
	messagesData: {},
	setMessagesData: (id, data) =>
		set((state) => ({
			messagesData: {
				...state.messagesData,
				[id]: {
					...state.messagesData[id],
					...data,
				},
			},
		})),
}))
