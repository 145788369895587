import { FirstScreen } from "components/ui/FirstScreen/FirstScreen"
import { FormRegistration } from "./layout/FormRegistration/FormRegistration"
import style from "./PageRegistration.module.scss"

const { formRegistration } = style

export const PageRegistration = () => {
	return (
		<FirstScreen>
			<div className={formRegistration} data-testid="formRegistration">
				<FormRegistration />
			</div>
		</FirstScreen>
	)
}
