export const CheckMarkIcon = () => {
	return (
		<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_5317_165763)">
				<circle cx="32" cy="32" r="32" fill="#CCDAFF" />
				<path
					d="M29.8384 41.6599C29.2411 41.6599 28.6438 41.4333 28.1878 40.9773L21.8598 34.6493C20.9478 33.7373 20.9478 32.2599 21.8598 31.3506C22.7718 30.4386 24.2464 30.4359 25.1584 31.3479L29.8384 36.0279L40.8464 25.0199C41.7584 24.1079 43.2331 24.1079 44.1451 25.0199C45.0571 25.9319 45.0571 27.4093 44.1451 28.3213L31.4891 40.9773C31.0331 41.4333 30.4358 41.6599 29.8384 41.6599Z"
					fill="#0047FF"
				/>
			</g>
			<defs>
				<clipPath id="clip0_5317_165763">
					<rect width="64" height="64" fill="white" />
				</clipPath>
			</defs>
		</svg>
	)
}
