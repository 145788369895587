import { useMutation, useQuery } from "@tanstack/react-query"
import objectsCategoriesService from "services/objectsCategories.service"

export const useObjectsCategories = () => {
	return useQuery({
		queryKey: ["objectsCategories"],
		queryFn: () => objectsCategoriesService.getObjectsCategories(),
		select: ({ data }) => data,
	})
}

export const useIncludeInProcessing = () => {
	return useMutation({
		mutationKey: ["includeInProcessing"],
		mutationFn: ({ isIncludedInProcessing, categoryId }: { categoryId: number; isIncludedInProcessing: boolean }) =>
			objectsCategoriesService.includeInProcessing(isIncludedInProcessing, categoryId),
	})
}

export const useUpdateModelNumbers = () => {
	return useMutation({
		mutationKey: ["updateModelNumbers"],
		mutationFn: ({ modelNumbers, categoryId }: { modelNumbers: string[]; categoryId: number | null }) =>
			objectsCategoriesService.updateModelNumbers(modelNumbers, categoryId),
	})
}
