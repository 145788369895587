import { useUploadFilesDataStore } from "store/uploadFiles.store"
// import { ButtonUploadMore } from "components/ui/buttons/ButtonUploadMore/ButtonUploadMore"
import { UploadbarFile } from "../UploadbarFile/UploadbarFile"
import style from "./UploadbarAllFiles.module.scss"

const { uploadbarFiles, wrapper, file } = style

export const UploadbarAllFiles = () => {
	const { files } = useUploadFilesDataStore()

	const sortedFilesByName = files.sort((a, b) => {
		const nameA = a.name
		const nameB = b.name

		if (nameA && nameB) {
			if (nameA < nameB) {
				return -1
			}
			if (nameA > nameB) {
				return 1
			}
		}

		return 0
	})

	return (
		<div className={uploadbarFiles}>
			<div className={wrapper}>
				{sortedFilesByName &&
					sortedFilesByName.map((item) => (
						<div className={file} key={item.id}>
							<UploadbarFile file={item} />
						</div>
					))}
			</div>
			{/* <ButtonUploadMore className={button}>Upload more files</ButtonUploadMore> */}
		</div>
	)
}
