import { useState } from "react"
import { Segmented } from "components/ui/segmented/Segmented"
import { useDrawingKeywordsConfig } from "hooks/drawings.hook"
import { FileInfoKeywords } from "../FileInfoKeywords/FileInfoKeywords"
import { useSearchParams } from "react-router-dom"
import { ConfigurationsObjects } from "./layout/ConfigurationsObjects/ConfigurationsObjects"
import type { TDrawerKeywords, TDrawingStatusProps } from "types/drawer.type"
import style from "./Configurations.module.scss"

const { configurations, segmented } = style

export const Configurations = ({ drawingStatus }: TDrawingStatusProps) => {
	const [segmentedOption, setSegmentedOption] = useState<string | number>("Keywords")
	const segmentedOptions = ["Keywords", "Objects"]

	const [searchParams] = useSearchParams()

	const drawerId = Number(searchParams.get("drawingID"))
	const processingId = Number(searchParams.get("processingID"))

	const {
		data: drawingKeywords,
		isPending: isKeywordsPending,
		isError: errorFetchingKewords,
	} = useDrawingKeywordsConfig(drawerId, processingId, drawingStatus)
	const keywords = drawingKeywords as TDrawerKeywords

	const segmentedOptionChangeHandle = (value: string | number) => {
		setSegmentedOption(value)
	}

	return (
		<div className={configurations}>
			<div className={segmented}>
				<Segmented options={segmentedOptions} onChange={segmentedOptionChangeHandle} />
			</div>
			{segmentedOption === "Keywords" ? (
				<FileInfoKeywords
					drawingKeywords={keywords}
					isKeywordsPending={isKeywordsPending}
					errorFetchingKewords={errorFetchingKewords}
				/>
			) : null}
			<div data-testid="configurationsObjects">
				{segmentedOption === "Objects" ? <ConfigurationsObjects drawingStatus={drawingStatus} /> : null}
			</div>
		</div>
	)
}
