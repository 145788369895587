import { useMutation, useQuery } from "@tanstack/react-query"
import drawingService from "services/drawing.service"
import { usePaginationStore } from "store/table.store"
import type { TDrawingStatus, TGeneralFeedbackQuery } from "types/drawer.type"

export const useDrawings = () => {
	const { tableParams } = usePaginationStore()
	const page = tableParams?.pagination.current
	const owner = tableParams?.filter.author

	return useQuery({
		queryKey: ["drawings", page, owner],
		queryFn: () => drawingService.getDrawings(page, owner),
		select: ({ data }) => data,
	})
}

export const useDrawing = (id: number | null) => {
	return useQuery({
		queryKey: ["drawingById", id],
		queryFn: () => drawingService.getDrawing(id),
		select: ({ data }) => data,
		enabled: !!id,
	})
}

export const useDrawingWithProcessings = (drawingId: number | null, processingId: string | number | null) => {
	return useQuery({
		queryKey: ["drawingWithProcessing", drawingId, processingId],
		queryFn: () => drawingService.getDrawingWithProcessings(drawingId, processingId),
		select: ({ data }) => data,
		enabled: !!drawingId && !!processingId,
	})
}

export const useDocuments = (drawingId: number, processingId: string | number | null, drawingStatus: string) => {
	const isEnabled = !!drawingId && (drawingStatus === "uploaded" || !!processingId)
	return useQuery({
		queryKey: ["documents", drawingId, processingId],
		queryFn: () => drawingService.getDocuments(drawingId, processingId, drawingStatus),
		select: ({ data }) => data,
		enabled: isEnabled,
	})
}

export const useDrawingKeywords = (
	drawingId: number | null,
	processingId: string | number | null,
	drawingStatus: TDrawingStatus,
) => {
	return useQuery({
		queryKey: ["drawingKeywords", drawingId, processingId],
		queryFn: () => drawingService.getDrawingKeywords(drawingId, processingId),
		select: ({ data }) => data,
		enabled: !!drawingId && !!processingId && drawingStatus === "processed",
	})
}

export const useDrawingKeywordsConfig = (
	drawingId: number | null,
	processingId: string | number | null,
	drawingStatus: TDrawingStatus,
) => {
	return useQuery({
		queryKey: ["drawingKeywordsConfig", drawingId, processingId],
		queryFn: () => drawingService.getDrawingKeywordsConfig(drawingId, processingId),
		select: ({ data }) => data,
		enabled: !!drawingId && !!processingId && drawingStatus === "processed",
	})
}

export const useDrawingObjects = (
	drawingId: number | null,
	processingId: string | number | null,
	drawingStatus: TDrawingStatus,
) => {
	return useQuery({
		queryKey: ["drawingObjects", drawingId, processingId],
		queryFn: () => drawingService.getDrawingObjects(drawingId, processingId),
		select: ({ data }) => data,
		enabled: !!drawingId && !!processingId && drawingStatus === "processed",
	})
}

export const useDrawingObjectsConfig = (
	drawingId: number | null,
	processingId: string | number | null,
	drawingStatus: TDrawingStatus,
) => {
	return useQuery({
		queryKey: ["drawingObjectsConfig", drawingId, processingId],
		queryFn: () => drawingService.getDrawingObjectsConfig(drawingId, processingId),
		select: ({ data }) => data,
		enabled: !!drawingId && !!processingId && drawingStatus === "processed",
	})
}

export const useDeleteDrawing = () => {
	return useMutation({
		mutationKey: ["deleteDrawing"],
		mutationFn: (id: number | undefined) => drawingService.delDrawing(id),
	})
}

export const useProcessings = (id: number) => {
	return useQuery({
		queryKey: ["processings", id],
		queryFn: () => drawingService.getProcessingRuns(id),
		select: ({ data }) => data,
		enabled: !!id,
	})
}

export const useDuplicateDrawing = () => {
	return useMutation({
		mutationKey: ["duplicateDrawing"],
		mutationFn: (id: number) => drawingService.duplicateDrawing(id),
	})
}

export const usePostGeneralFeedback = () => {
	return useMutation({
		mutationKey: ["generalFeedback"],
		mutationFn: ({ formData, drawingId, processingId }: TGeneralFeedbackQuery) =>
			drawingService.postGeneralFeedback(formData, drawingId, processingId),
	})
}

export const useDrawingVersions = (
	drawingId: number | null,
	processingId: string | number | null,
	drawingStatus: TDrawingStatus,
) => {
	return useQuery({
		queryKey: ["drawingVersions", drawingId, processingId],
		queryFn: () => drawingService.getDrawingVersions(drawingId, processingId),
		select: ({ data }) => data,
		enabled: !!drawingId && !!processingId && (drawingStatus === "processed" || drawingStatus === "failed"),
	})
}
