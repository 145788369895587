import { axiosInstance } from "api/axios.instance"

class DrawingsService {
	static BASE_URL = "/drawings"

	async getDrawings(page: number, owner: string | null) {
		const ownerParam = owner === null ? "" : `&owner=${owner}`
		return axiosInstance.get(`${DrawingsService.BASE_URL}/?page=${page}${ownerParam}`)
	}

	async getDrawing(id: number | null) {
		return axiosInstance.get(`${DrawingsService.BASE_URL}/${id}/`)
	}

	async getDrawingWithProcessings(drawingId: number | null, processingId: string | number | null) {
		return axiosInstance.get(`${DrawingsService.BASE_URL}/${drawingId}/processings/${processingId}/`)
	}

	async getDocuments(drowingId: number, processingId: string | number | null, drawingStatus: string) {
		const urlWithoutProcessing = `${DrawingsService.BASE_URL}/${drowingId}/documents`
		const urlWithProcessings = `${DrawingsService.BASE_URL}/${drowingId}/processings/${processingId}/documents`
		const url = drawingStatus === "uploaded" ? urlWithoutProcessing : urlWithProcessings

		return axiosInstance.get(url)
	}

	async getDrawingKeywords(drawingId: number | null, processingId: string | number | null) {
		return axiosInstance.get(`${DrawingsService.BASE_URL}/${drawingId}/processings/${processingId}/keywords`)
	}

	async getDrawingKeywordsConfig(drawingId: number | null, processingId: string | number | null) {
		return axiosInstance.get(`${DrawingsService.BASE_URL}/${drawingId}/processings/${processingId}/key-phrases-config/`)
	}

	async getDrawingObjects(drawingId: number | null, processingId: string | number | null) {
		return axiosInstance.get(`${DrawingsService.BASE_URL}/${drawingId}/processings/${processingId}/objects-found`)
	}

	async getDrawingObjectsConfig(drawingId: number | null, processingId: string | number | null) {
		return axiosInstance.get(`${DrawingsService.BASE_URL}/${drawingId}/processings/${processingId}/objects-config/`)
	}

	async delDrawing(id: number | undefined) {
		return axiosInstance.delete(`${DrawingsService.BASE_URL}/${id}/`)
	}

	async getProcessingRuns(id: number) {
		return axiosInstance.get(`${DrawingsService.BASE_URL}/${id}/processings/`)
	}

	async duplicateDrawing(id: number) {
		return axiosInstance.post(`${DrawingsService.BASE_URL}/${id}/duplicate/`)
	}

	async postGeneralFeedback(formData: FormData, drawingId: number, processingId: number | string | null) {
		return axiosInstance.post(
			`${DrawingsService.BASE_URL}/${drawingId}/processings/${processingId}/generalFeedback/`,
			formData,
			{
				headers: { "Content-Type": "multipart/form-data" },
				timeout: 0,
			},
		)
	}

	async getDrawingVersions(drawingId: number | null, processingId: string | number | null) {
		return axiosInstance.get(`${DrawingsService.BASE_URL}/${drawingId}/processings/${processingId}/sw-versions`)
	}
}

export default new DrawingsService()
