import { ConfigProvider } from "antd"
import { ReactNode } from "react"

type Props = {
	children: ReactNode
}

export const CollapseBlueProvider = ({ children }: Props) => {
	return (
		<ConfigProvider
			theme={{
				token: {
					padding: 12,
					paddingLG: 0,
				},
				components: {
					Collapse: {
						fontSizeLG: 18,
						contentPadding: 0,
					},
				},
			}}
		>
			{children}
		</ConfigProvider>
	)
}
