import { useMutation } from "@tanstack/react-query"
import type { IExtendetUploadFile, TCheckUploadFileHash, TUploadFinished } from "types/uploadFiles.type"
import UploadFilesService from "services/uploadFiles.service"

export const useUploadFile = () => {
	return useMutation({
		mutationKey: ["uploadFile"],
		mutationFn: ({ presignedUrl, file, onProgress, abortController }: IExtendetUploadFile) => {
			return UploadFilesService.uploadFile({
				presignedUrl,
				file,
				onProgress,
				abortRequestSignal: abortController?.signal,
			})
		},
	})
}

export const useUploadFileHash = () => {
	return useMutation({
		mutationKey: ["checkUploadFileHash"],
		mutationFn: ({ hash, fileName }: TCheckUploadFileHash) => UploadFilesService.uploadFileHash(hash, fileName),
	})
}

export const useUploadFinished = () => {
	return useMutation({
		mutationKey: ["uploadFinished"],
		mutationFn: ({ uploadId, result, reason }: TUploadFinished) =>
			UploadFilesService.uploadFinished({ uploadId, result, reason }),
	})
}
