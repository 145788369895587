import { ReactNode } from "react"
import { ConfigProvider } from "antd"

type Props = { children: ReactNode }

export const CheckboxProvier = ({ children }: Props) => {
	return (
		<ConfigProvider
			theme={{
				token: {
					colorPrimary: "#0047FF",
				},
			}}
		>
			{children}
		</ConfigProvider>
	)
}
