import { useMessage } from "hooks/message.hook"
import { useIncludeInProcessing } from "hooks/objectsCategories.hook"

export const CategoriesHandlers = () => {
	const { error: errorMessage } = useMessage()
	const { mutate: includeInProcessingMutate } = useIncludeInProcessing()

	const includeInProcessingHandle = (isIncludedInProcessing: boolean, categoryId: number) => {
		includeInProcessingMutate(
			{ isIncludedInProcessing, categoryId },
			{
				onError: () => {
					errorMessage("Something went wrong, please try again later")
				},
			},
		)
	}

	return { includeInProcessingHandle }
}
