import { useQuery } from "@tanstack/react-query"
import softwareVersionService from "services/softwareVersion.service"

export const useVersions = () => {
	return useQuery({
		queryKey: ["versions"],
		queryFn: () => softwareVersionService.getVersions(),
		select: ({ data }) => data,
	})
}
