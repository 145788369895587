import { DateIcon } from "components/ui/icons/processingDetails/DateIcon"
import { TimeIcon } from "components/ui/icons/processingDetails/TimeIcon"
import { formatDate } from "helpers/date"
import { formatTime } from "helpers/utils.helper"
import { useDrawing, useDrawingWithProcessings } from "hooks/drawings.hook"
import { useSearchParams } from "react-router-dom"
import { Skeleton } from "components/ui/skeleton/Skeleton"
import { H3 } from "components/ui/typography/titles/Titles"
import { AxiosError } from "axios"
import style from "./Details.module.scss"

const { details, item, letter, block, descr, value, warningMessage } = style

export const Details = () => {
	const [searchParams] = useSearchParams()

	const drawingId = Number(searchParams.get("drawingID"))
	const processingId = Number(searchParams.get("processingID"))

	const { data: drawingWithProcessings, isPending: isDrawingInfoWithProcessingPending } = useDrawingWithProcessings(
		drawingId,
		processingId,
	)
	const { data: uploadDrawing, isPending: isDrawingInfoPending, error: drowingByIdError } = useDrawing(drawingId)

	const drawingStatus = uploadDrawing?.status
	const finalDrawing = drawingStatus !== "uploaded" ? drawingWithProcessings : uploadDrawing // Status of current

	// If no access display warning
	if (drowingByIdError instanceof AxiosError) {
		const status = drowingByIdError?.response?.status
		if (status === 403) {
			return <H3 className={warningMessage}>You don't have permission to access this resource.</H3>
		}
	}

	return (
		<>
			{isDrawingInfoPending || (isDrawingInfoWithProcessingPending && drawingStatus !== "uploaded") ? (
				<Skeleton rows={2} />
			) : (
				<div className={details}>
					<div className={item}>
						<div className={letter}>{finalDrawing?.ownerFirstName.charAt(0).toUpperCase()}</div>
						<div className={block}>
							<span className={descr}>Uploaded by</span>
							<span className={value}>{finalDrawing?.ownerFirstName + " " + finalDrawing?.ownerLastName}</span>
						</div>
					</div>
					<div className={item}>
						<DateIcon />
						<div className={block}>
							<span className={descr}>Uploaded</span>
							<span className={value}>{finalDrawing?.uploadDate && formatDate(finalDrawing?.uploadDate)}</span>
						</div>
					</div>
					{finalDrawing?.lastProcessingDate ? (
						<div className={item}>
							<DateIcon />
							<div className={block}>
								<span className={descr}>Processed</span>
								<span className={value}>{formatDate(finalDrawing?.lastProcessingDate)}</span>
							</div>
						</div>
					) : null}
					{finalDrawing?.processing_duration ? (
						<div className={item}>
							<TimeIcon />
							<div className={block}>
								<span className={descr}>Processing duration</span>
								<span className={value}>
									{finalDrawing?.processing_duration && formatTime(finalDrawing?.processing_duration)}
								</span>
							</div>
						</div>
					) : null}
				</div>
			)}
		</>
	)
}
