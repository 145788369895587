import { H2 } from "components/ui/typography/titles/Titles"
import style from "./ValidationCheck.module.scss"

const { title, text, contact } = style

type Props = { message: string; email: string }

export const ValidationCheck = ({ message, email }: Props) => {
	return (
		<>
			<H2 className={title}>Oops, we're sorry...</H2>
			<p className={text}>{message}</p>
			<a className={contact} href={email}>
				Contact us
			</a>
		</>
	)
}
