import { InputSearch } from "components/ui/inputs/InputSearch"
import { H1 } from "components/ui/typography/titles/Titles"
import { TOnChangeInput } from "types/default.type"
import { UploadFiles } from "../UploadFiles/UploadFiles"
import style from "./TopTableBar.module.scss"

const { TopTableBarStyle, wrapper, uploadFiles, inputSearch } = style

type Props = {
	totalRecords: number
}

export const TopTableBar = ({ totalRecords }: Props) => {
	const searchHandle = (e: TOnChangeInput) => {
		const value = e.target.value
		console.log(value)
	}
	return (
		<div className={TopTableBarStyle}>
			<H1>
				All files <sup data-testid="recordsSup">{totalRecords && totalRecords}</sup>
			</H1>
			<div className={wrapper}>
				<div className={inputSearch}>
					<InputSearch onChange={(e) => searchHandle(e)} width={320} disabled={true} />
				</div>
				<div className={uploadFiles}>
					<UploadFiles />
				</div>
			</div>
		</div>
	)
}
