import { ConfigProvider } from "antd"
import { ReactNode } from "react"

type Props = {
	children: ReactNode
}

export const SegmentedProvider = ({ children }: Props) => {
	return (
		<ConfigProvider
			theme={{
				components: {
					Segmented: {
						itemSelectedColor: "#0047FF",
						trackPadding: 8,
					},
				},
				token: {
					fontSizeLG: 18,
					controlPaddingHorizontal: 32,
					controlHeightLG: 58,
					borderRadiusLG: 18,
					borderRadius: 10,
				},
			}}
		>
			{children}
		</ConfigProvider>
	)
}
