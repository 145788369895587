import { AxiosError } from "axios"

const capitalizeFirstLetter = (string: string) => {
	return string.charAt(0).toUpperCase() + string.slice(1)
}

const removeApiVersion = (url: string): string => {
	return url.replace("/api/v1/", "/")
}

const copyToClipboard = (str: string) => {
	navigator.clipboard.writeText(str)
}

const isAxiosError = (error: unknown): error is AxiosError => {
	return (error as AxiosError).isAxiosError !== undefined
}

const formatTime = (time: string) => {
	const [hours, minutes, seconds] = time.split(":").map(Number)

	const formatedHours = hours > 0 ? `${hours}` : ""
	const formatedMinutes = minutes > 0 ? `${minutes}m` : ""
	const formatedSeconds = `${seconds}s`

	return `${formatedHours} ${formatedMinutes} ${formatedSeconds}`
}

const objectTypeTextTooltip = (type: string) => {
	switch (type) {
		case "left_part_of_dual_object":
		return "Left part"

		case "right_part_of_dual_object":
			return "Right part"

		case "whole_dual_object":
			return "Full object"		

		case "shelves_object":
			return "Shelves object"
	}
}

export { capitalizeFirstLetter, removeApiVersion, copyToClipboard, isAxiosError, formatTime, objectTypeTextTooltip }
