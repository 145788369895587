import { useEffect } from "react"
import * as Sentry from "@sentry/react"
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom"
import { Routes } from "react-router-dom"

// Access environment variables
const apiUrl = import.meta.env.VITE_API_URL
const natsUrl = import.meta.env.VITE_NATS_URL
const env = import.meta.env.VITE_ENV
const projectUrl = import.meta.env.VITE_SENTRY_PROJECT_URL

if (env != "local") {
	Sentry.init({
		dsn: projectUrl,
		integrations: [
			// See docs for support of different versions of variation of react router
			// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
			Sentry.reactRouterV6BrowserTracingIntegration({
				useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes,
			}),
			Sentry.replayIntegration(),
		],

		// Environment mode
		environment: env,

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for tracing.
		tracesSampleRate: 1.0,

		// Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
		tracePropagationTargets: [apiUrl, natsUrl],

		// Capture Replay for 10% of all sessions,
		replaysSessionSampleRate: 0.1,
		// plus for 100% of sessions with an error
		replaysOnErrorSampleRate: 1.0,
	})
}

// Exporting the Sentry Routes and passing the Routes from react router
export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)
