export const InModelFalseIcon = () => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				opacity="0.2"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.7004 1.6665H6.3013C3.52937 1.6665 1.66797 3.61387 1.66797 6.51387V13.4858C1.66797 16.3849 3.52937 18.3332 6.3013 18.3332H13.6995C16.4715 18.3332 18.3346 16.3849 18.3346 13.4858V6.51387C18.3346 3.61387 16.4724 1.6665 13.7004 1.6665Z"
				fill="#EC404C"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.3878 12.3815C12.2662 12.5032 12.1062 12.5648 11.9462 12.5648C11.7862 12.5648 11.6262 12.5032 11.5037 12.3815L9.99868 10.8757L8.49785 12.3773C8.25365 12.6215 7.85781 12.6215 7.61365 12.3773C7.37031 12.1332 7.37031 11.7382 7.61365 11.494L9.11451 9.99234L7.61281 8.48984C7.36865 8.24563 7.36865 7.85063 7.61281 7.60646C7.85698 7.3623 8.25198 7.3623 8.49618 7.60646L9.99868 9.10817L11.4995 7.6073C11.7437 7.36397 12.1387 7.36397 12.3828 7.6073C12.627 7.85147 12.627 8.2473 12.3828 8.49151L10.8828 9.99234L12.3878 11.4973C12.632 11.7415 12.632 12.1373 12.3878 12.3815Z"
				fill="#EC404C"
			/>
		</svg>
	)
}
