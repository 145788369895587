import { ReactNode } from "react"
import style from "./SidebarLeft.module.scss"

const { sidebarLeft } = style

type Props = {
	children: ReactNode
}

export const SidebarLeft = ({ children }: Props) => {
	return <aside className={sidebarLeft}>{children}</aside>
}
