import { ConfigProvider } from "antd"
import { ReactNode } from "react"

type Props = {
	children: ReactNode
}

export const DrawerProvider = ({ children }: Props) => {
	return (
		<ConfigProvider
			theme={{
				token: {
					paddingLG: 0,
				},
			}}
		>
			{children}
		</ConfigProvider>
	)
}
