import { TopTableBar } from "./layout/TopTableBar/TopTableBar"
import { TableAllFiles } from "./layout/TableAllFiles/TableAllFiles"
import { useDrawings } from "hooks/drawings.hook"
import { Uploadbar } from "components/pages/PageAllFiles/layout/Uploadbar/Uploadbar"
import { UploadbarMini } from "./layout/Uploadbar/layout/UploadbarMini/UploadbarMini"
import { useUploadbarEnabledStore, useUploadbarOpenStore } from "store/uploadFiles.store"
import { Skeleton } from "components/ui/skeleton/Skeleton"
import style from "./PageAllFiles.module.scss"
import { useUserProfile } from "hooks/user.hook"

const { PageAllFilesStyle, container } = style

export const PageAllFiles = () => {
	const {
		data: drawings,
		refetch: refetchDrawings,
		isRefetching: isDrawingsRefetching,
		isPending: isDrawingsPending,
		isSuccess: isDrawingsSuccess,
	} = useDrawings()
	const { isSuccess: isUserProfileSuccess } = useUserProfile()

	const { isOpen } = useUploadbarOpenStore()
	const { isEnabled } = useUploadbarEnabledStore()

	return (
		<>
			<section className={PageAllFilesStyle}>
				<div className={container}>
					<TopTableBar totalRecords={drawings?.totalRecords} />
					{isDrawingsPending ? (
						<Skeleton rows={16} />
					) : isDrawingsSuccess && isUserProfileSuccess ? (
						<TableAllFiles
							data={drawings}
							refetchDrawings={refetchDrawings}
							isDrawingsRefetching={isDrawingsRefetching}
						/>
					) : (
						<div>Failed to load files. Please, reload the page or try again later</div>
					)}
				</div>
			</section>
			{isEnabled && <div>{isOpen === true ? <Uploadbar /> : <UploadbarMini />}</div>}
		</>
	)
}
