import { Tabs as AntTabs } from "antd"
import { TabsDrawerProvider } from "components/providers/Tabs.provider"
import type { TabsProps } from "antd"

type Props = {
	items: TabsProps["items"]
	onChangeHandle: (activeKey: string) => void
	activeKey: string
}

export const TabsDrawer = ({ items, onChangeHandle, activeKey }: Props) => {
	return (
		<TabsDrawerProvider>
			<AntTabs type="card" items={items} size="large" onChange={onChangeHandle} activeKey={activeKey} />
		</TabsDrawerProvider>
	)
}
