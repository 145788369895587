import { H1 } from "components/ui/typography/titles/Titles"
import style from "./ObjectsCategories.module.scss"
import { AddModelObject } from "./layout/AddModelObject/AddModelObject"
import { Categories } from "./layout/Categories/Categories"

const { objectsCategories, topBlock } = style

export const ObjectsCategories = () => {
	return (
		<div className={objectsCategories}>
			<div className={topBlock}>
				<H1>Categories of objects</H1>
				<AddModelObject />
			</div>
			<div>
				<Categories />
			</div>
		</div>
	)
}
