import { Progress } from "antd"
import { useUploadFilesDataStore, useUploadbarOpenStore } from "store/uploadFiles.store"
import { useEffect, useState } from "react"
import style from "./UploadbarMini.module.scss"

const { uploadbarMini, wrapper, progress } = style

type FilesCount = {
	total?: number | null
	completed?: number | null
}

export const UploadbarMini = () => {
	const { setOpen } = useUploadbarOpenStore()
	const { files } = useUploadFilesDataStore()

	const [filesCount, setFilesCount] = useState<FilesCount>({
		total: null,
		completed: null,
	})
	const [completedPercentage, setCompletedPercentage] = useState(0)

	const uploadedCount = Object.values(files).reduce((count, { status }) => {
		return status === "uploaded" ? ++count : count
	}, 0)

	useEffect(() => {
		setFilesCount({
			total: Object.keys(files).length,
			completed: uploadedCount,
		})
		if (filesCount.completed && filesCount.total) {
			const completed = (filesCount?.completed / filesCount?.total) * 100
			setCompletedPercentage(completed)
		}
	}, [files, filesCount?.completed, filesCount?.total, uploadedCount])

	const onClickHandle = () => {
		setOpen()
	}

	return (
		<button className={uploadbarMini} onClick={onClickHandle}>
			<div className={wrapper}>
				<Progress type="circle" percent={completedPercentage} size={50} showInfo={false} />
				<div className={progress}>{filesCount?.completed + "/" + filesCount?.total}</div>
			</div>
		</button>
	)
}
