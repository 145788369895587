import { useCallback, useMemo } from "react"
import "react-toastify/dist/ReactToastify.css"
import { toast, ToastOptions, ToastPosition } from "react-toastify"

export const useMessage = () => {
	const baseToastOptions = useMemo<ToastOptions>(
		() => ({
			position: "bottom-left" as ToastPosition,
			autoClose: 4000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		}),
		[],
	)

	const notify = useCallback(
		(text: string, options?: ToastOptions) => {
			if (text) {
				toast(text, { ...baseToastOptions, ...options })
			}
		},
		[baseToastOptions],
	)

	const success = useCallback(
		(text: string) => {
			notify(text, { type: "success" })
		},
		[notify],
	)

	const error = useCallback(
		(text: unknown) => {
			if (typeof text === "string") {
				notify(text, { type: "error" })
			} else if (text instanceof Error) {
				notify(text.message, { type: "error" })
			} else {
				notify("Error", { type: "error" })
			}
		},
		[notify],
	)

	return { notify, success, error }
}
