export const InternalUserIcon = () => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M7.5013 9.16797C7.96154 9.16797 8.33464 8.79487 8.33464 8.33464C8.33464 7.8744 7.96154 7.5013 7.5013 7.5013C7.04107 7.5013 6.66797 7.8744 6.66797 8.33464C6.66797 8.79487 7.04107 9.16797 7.5013 9.16797Z"
				fill="#0047FF"
			/>
			<path
				d="M13.3346 8.33464C13.3346 8.79487 12.9615 9.16797 12.5013 9.16797C12.0411 9.16797 11.668 8.79487 11.668 8.33464C11.668 7.8744 12.0411 7.5013 12.5013 7.5013C12.9615 7.5013 13.3346 7.8744 13.3346 8.33464Z"
				fill="#0047FF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.0013 1.66797C5.39893 1.66797 1.66797 5.39893 1.66797 10.0013C1.66797 14.6037 5.39893 18.3346 10.0013 18.3346C14.6037 18.3346 18.3346 14.6037 18.3346 10.0013C18.3346 5.39893 14.6037 1.66797 10.0013 1.66797ZM3.33464 10.0013C3.33464 6.3194 6.3194 3.33464 10.0013 3.33464C13.6832 3.33464 16.668 6.3194 16.668 10.0013C16.668 13.6832 13.6832 16.668 10.0013 16.668C6.3194 16.668 3.33464 13.6832 3.33464 10.0013Z"
				fill="#0047FF"
			/>
			<path
				d="M8.14542 11.9726C7.85655 11.6205 7.33737 11.5653 6.98072 11.8506C6.62134 12.1381 6.5641 12.6638 6.85161 13.0232L6.92534 13.1084C6.96467 13.1514 7.01872 13.2074 7.0876 13.272C7.22503 13.4009 7.42376 13.5658 7.68464 13.7288C8.20968 14.057 8.98565 14.3763 10.0013 14.3763C11.017 14.3763 11.7929 14.057 12.318 13.7288C12.5788 13.5658 12.7776 13.4009 12.915 13.272C12.9839 13.2074 13.0379 13.1514 13.0773 13.1084L13.152 13.0219C13.4395 12.6625 13.3813 12.1381 13.0219 11.8506C12.6652 11.5653 12.1461 11.6205 11.8572 11.9726L11.8472 11.9838C11.8345 11.9977 11.8104 12.023 11.7751 12.0561C11.7042 12.1226 11.5904 12.2181 11.4346 12.3155C11.1263 12.5082 10.6523 12.7096 10.0013 12.7096C9.35029 12.7096 8.87626 12.5082 8.56797 12.3155C8.41218 12.2181 8.29841 12.1226 8.2275 12.0561C8.19222 12.023 8.16815 11.9977 8.15539 11.9838L8.14542 11.9726Z"
				fill="#0047FF"
			/>
		</svg>
	)
}
