export const AboutIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
			<path
				opacity="0.4"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.5 2.5C7.262 2.5 3 6.761 3 12C3 17.238 7.262 21.5 12.5 21.5C17.738 21.5 22 17.238 22 12C22 6.761 17.738 2.5 12.5 2.5Z"
				fill="#0047FF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.256 15.693C13.256 16.107 12.92 16.443 12.506 16.443C12.092 16.443 11.756 16.107 11.756 15.693V11.394C11.756 10.98 12.092 10.644 12.506 10.644C12.92 10.644 13.256 10.98 13.256 11.394V15.693ZM11.75 8.29102C11.75 7.87702 12.086 7.54102 12.5 7.54102C12.914 7.54102 13.25 7.87702 13.25 8.29102C13.25 8.70502 12.914 9.07302 12.5 9.07302C12.086 9.07302 11.75 8.76802 11.75 8.35402V8.29102Z"
				fill="#0047FF"
			/>
		</svg>
	)
}
