import { Input } from "antd"
import { TextAreaProvider } from "components/providers/Input.provider"
import { TOnChangeInput } from "types/default.type"

const { TextArea: AntTextArea } = Input

type Props = {
	value?: string
	placeholder: string
	minRows?: number
	onChange?: (args: TOnChangeInput) => void
}

export const TextArea = ({ value, placeholder, minRows = 5, onChange }: Props) => {
	return (
		<TextAreaProvider>
			<AntTextArea value={value} onChange={onChange} placeholder={placeholder} autoSize={{ minRows: minRows }} />
		</TextAreaProvider>
	)
}
