import { useState } from "react"
import { Segmented } from "components/ui/segmented/Segmented"
import { FileInfoKeywords } from "../FileInfoKeywords/FileInfoKeywords"
import { FoundItemsObjects } from "./layout/FoundItemsObjects/FoundItemsObjects"
import { useSearchParams } from "react-router-dom"
import { useDrawingKeywords } from "hooks/drawings.hook"
import type { TDrawerKeywords, TDrawingStatusProps } from "types/drawer.type"
import style from "./FoundItems.module.scss"

const { foundItems, segmented } = style

export const FoundItems = ({ drawingStatus }: TDrawingStatusProps) => {
	const [segmentedOption, setSegmentedOption] = useState<string | number>("Keywords")
	const segmentedOptions = ["Keywords", "Objects"]

	const [searchParams] = useSearchParams()

	const drawerId = Number(searchParams.get("drawingID"))
	const processingId = Number(searchParams.get("processingID"))

	const {
		data: keywords,
		isPending: isKeywordsPending,
		isError: errorFetchingKewords,
	} = useDrawingKeywords(drawerId, processingId, drawingStatus)
	const drawingKeywords = keywords as TDrawerKeywords

	const segmentedOptionChangeHandle = (value: string | number) => {
		setSegmentedOption(value)
	}

	return (
		<div className={foundItems}>
			<div className={segmented}>
				<Segmented options={segmentedOptions} onChange={segmentedOptionChangeHandle} />
			</div>
			<div data-testid="keywordsFound">
				{segmentedOption === "Keywords" ? (
					<FileInfoKeywords
						drawingKeywords={drawingKeywords}
						isKeywordsPending={isKeywordsPending}
						errorFetchingKewords={errorFetchingKewords}
					/>
				) : null}
			</div>
			<div data-testid="objectsFound">
				{segmentedOption === "Objects" ? <FoundItemsObjects drawingStatus={drawingStatus} /> : null}
			</div>
		</div>
	)
}
