import { H1 } from "components/ui/typography/titles/Titles"
import { useVersions } from "hooks/softwareVersion.hook"
import { SoftwareVersions } from "components/ui/SoftwareVersions/SoftwareVersions"
import style from "./PageAbout.module.scss"

const appVersion = import.meta.env.VITE_APP_VERSION

const { pageAbout, container, title } = style

export const PageAbout = () => {
	const { data: versions, isPending: isVersionsPending, isError: isVersionsError } = useVersions()

	return (
		<section className={pageAbout}>
			<div className={container}>
				<H1 className={title}>Stevens Inc. AutoScan</H1>
				<SoftwareVersions
					versions={versions}
					frontEndVersion={appVersion}
					isVersionsPending={isVersionsPending}
					isVersionsError={isVersionsError}
				/>
			</div>
		</section>
	)
}
