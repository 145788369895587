import { ConfigProvider } from "antd"
import { ReactNode } from "react"

type Props = {
	children: ReactNode
}

export const TableProvider = ({ children }: Props) => {
	return (
		<ConfigProvider
			theme={{
				components: {
					Table: {
						headerBg: "transparent",
						headerSplitColor: "none",
						fontSize: 18,
						headerColor: "#BEC2D6",
					},
				},
				token: {
					fontSize: 14,
				},
			}}
		>
			{children}
		</ConfigProvider>
	)
}

export const TableObjectsProvider = ({ children }: Props) => {
	return (
		<ConfigProvider
			theme={{
				components: {
					Table: {
						headerBg: "#F2F3F7",
						headerSplitColor: "none",
						headerColor: "#BEC2D6",
						cellFontSize: 14,
						headerBorderRadius: 10,
						borderColor: "#DEE0EB",
					},
				},
			}}
		>
			{children}
		</ConfigProvider>
	)
}
