type Props = {
	rotate: number
}

export const CollapseBlueIcon = ({ rotate }: Props) => {
	return (
		<svg
			style={{ transform: `rotate(${rotate}deg)` }}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="24" height="24" rx="4" fill="#CCDAFF" />
			<path
				d="M13.8667 8.94035C15.5523 10.3598 16.3951 11.0695 16.3951 12C16.3951 12.9305 15.5523 13.6402 13.8667 15.0596L12.9324 15.8464C10.8005 17.6417 9.73454 18.5393 8.86727 18.1359C8 17.7324 8 16.3388 8 13.5517L8 10.4483C8 7.6612 8 6.26764 8.86727 5.86415C9.73454 5.46067 10.8005 6.35831 12.9324 8.1536L13.8667 8.94035Z"
				fill="#0047FF"
			/>
		</svg>
	)
}
