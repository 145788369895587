import { Checkbox as AntCheckbox } from "antd"
import { CheckboxProvier } from "components/providers/Checkbox.provider"
import type { CheckboxChangeEvent } from "antd/es/checkbox"

type Props = {
	defaultChecked: boolean
	onChange: (e: CheckboxChangeEvent) => void
}

export const Checkbox = ({ defaultChecked, onChange }: Props) => {
	return (
		<CheckboxProvier>
			<AntCheckbox defaultChecked={defaultChecked} onChange={onChange} />
		</CheckboxProvier>
	)
}
