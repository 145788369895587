import { Skeleton as AntSkeleton } from "antd"

type Props = {
	active?: boolean
	rows?: number
}

export const Skeleton = ({ active = true, rows = 4 }: Props) => {
	return <AntSkeleton active={active} paragraph={{ rows: rows }} />
}
