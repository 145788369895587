import { CheckMarkIcon } from "components/ui/icons/generalFeedback/CheckMarkIcon"
import styles from "./ThankYou.module.scss"

const { thankYou, title, text } = styles

export const ThankYou = () => {
	return (
		<div className={thankYou}>
			<CheckMarkIcon />
			<span className={title}>Thank you for your feedback!</span>
			<span className={text}>
				Our team will keep you updated via email with the outcome. Feel free to give feedback as many times as
				necessary.
			</span>
		</div>
	)
}
