import { ConfigProvider } from "antd"
import { ReactNode } from "react"

type Props = {
	children: ReactNode
}

export const ModalProvider = ({ children }: Props) => {
	return (
		<ConfigProvider
			theme={{
				components: {
					Modal: {
						titleFontSize: 24,
					},
				},
				token: {
					fontSize: 18,
					borderRadiusLG: 24,
				},
			}}
		>
			{children}
		</ConfigProvider>
	)
}
