import { Input } from "antd"
import { InputSearchProvider } from "components/providers/InputSearch.provider"

import { SearchIcon } from "components/ui/icons/input/SearchIcon"
import type { TOnChangeInput } from "types/default.type"

type Props = {
	onChange?: (args: TOnChangeInput) => void
	width?: string | number
	placeholder?: string
	disabled?: boolean
}

export const InputSearch = ({ onChange, width = "100%", placeholder = "Search by name", disabled = false }: Props) => {
	return (
		<InputSearchProvider>
			<Input
				placeholder={placeholder}
				prefix={<SearchIcon />}
				onChange={onChange}
				style={{ width: width }}
				size="large"
				disabled={disabled}
			/>
		</InputSearchProvider>
	)
}
