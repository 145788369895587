import { axiosInstance } from "api/axios.instance"

class SoftwareVersion {
	static BASE_URL = "sw-versions"

	async getVersions() {
		return axiosInstance.get(`${SoftwareVersion.BASE_URL}/`)
	}
}

export default new SoftwareVersion()
