import { Segmented as AntSegmented } from "antd"
import { SegmentedProvider } from "components/providers/Segmented.provider"
import type { SegmentedProps } from "antd"
import type { SizeType } from "antd/es/config-provider/SizeContext"

type Props = {
	options: SegmentedProps["options"]
	size?: SizeType
	onChange: (value: string | number) => void
}

export const Segmented = ({ options, size = "large", onChange }: Props) => {
	return (
		<SegmentedProvider>
			<AntSegmented options={options} size={size} onChange={onChange} />
		</SegmentedProvider>
	)
}
