import { InputSearch } from "components/ui/inputs/InputSearch"
import { PageTeamHeader } from "./layout/PageTeamHeader/PageTeamHeader"
import { TeamTable } from "./layout/TeamTable/TeamTable"
import { useUsers } from "hooks/user.hook"
import { Skeleton } from "components/ui/skeleton/Skeleton"
import style from "./PageTeam.module.scss"
import { useEffect } from "react"

const { pageTeam, container } = style

export const PageTeam = () => {
	const { data: allUsers, isPending: isUsersPending, refetch: refetchUsers } = useUsers()

	useEffect(() => {
		refetchUsers()
	}, [refetchUsers])

	return (
		<section className={pageTeam}>
			<div className={container}>
				<PageTeamHeader />
				{isUsersPending ? (
					<Skeleton rows={8} />
				) : (
					<>
						<InputSearch disabled={true} />
						<TeamTable allUsers={allUsers} />
					</>
				)}
			</div>
		</section>
	)
}
