type Props = {
	active: boolean
}

export const ThumbsDownIcon = ({ active }: Props) => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M18.6361 15.3442C19.2064 15.3442 19.4916 15.3442 19.7248 15.2784C20.3111 15.1129 20.7694 14.6547 20.9348 14.0683C21.0006 13.8351 21.0006 13.55 21.0006 12.9798C21.0006 10.6022 21.0006 8.13165 21.0006 5.75399C21.0006 5.18378 21.0006 4.89868 20.9348 4.66546C20.7694 4.07912 20.3111 3.62087 19.7248 3.45545C19.4916 3.38965 19.2064 3.38965 18.6361 3.38965C18.0657 3.38965 17.7806 3.38965 17.5474 3.45545C16.961 3.62087 16.5028 4.07912 16.3373 4.66546C16.2715 4.89868 16.2715 5.18378 16.2715 5.75399C16.2715 8.13165 16.2715 10.6022 16.2715 12.9798C16.2715 13.55 16.2715 13.8351 16.3373 14.0683C16.5028 14.6547 16.961 15.1129 17.5474 15.2784C17.7806 15.3442 18.0657 15.3442 18.6361 15.3442Z"
				stroke={active ? "#0047FF" : "black"}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M16.271 14.2623C14.8253 16.1316 13.3911 18.0104 11.9335 19.8704C11.4717 20.4596 10.6631 20.7565 9.93718 20.5454C8.31812 20.0748 8.73144 17.2034 9.15485 15.6805C9.46883 14.5512 9.08708 14.1595 8.16775 14.1595C6.15743 14.1595 5.20222 14.1595 4.5179 13.7483C3.892 13.3722 3.43243 12.7722 3.23228 12.07C2.81494 10.6058 2.95869 7.81656 3.67391 5.87255C4.1571 4.55921 5.51997 3.65599 6.70042 3.52498C8.29451 3.34806 10.5195 3.41187 12.1994 3.50515C13.7431 3.59086 15.1807 4.27764 16.271 5.37384"
				stroke={active ? "#0047FF" : "black"}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
