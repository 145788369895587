import { useState, useEffect } from "react"
import { Tag } from "components/ui/tag/Tag"
import { useModelNumbersModalStore } from "store/modal.store"
import { InputBorderless } from "components/ui/inputs/InputBorderless"
import { CloseIcon } from "components/ui/icons/close/CloseIcon"
import type { TOnChangeInput } from "types/default.type"
import style from "./EditableModelNumbers.module.scss"

const { editableModelNumbers, tags } = style

type Props = { setHasChanges: (arg: boolean) => void }

export const EditableModelNumbers = ({ setHasChanges }: Props) => {
	const [inputValue, setInputValue] = useState("")
	const { modelNumbers, updateModelNumbers, setModelNumbers, initialModelNumbers } = useModelNumbersModalStore()

	useEffect(() => {
		const hasChanges = JSON.stringify(initialModelNumbers) !== JSON.stringify(modelNumbers)
		setHasChanges(hasChanges)
	}, [modelNumbers, initialModelNumbers, setHasChanges])

	const onConfirmHandle = () => {
		if (inputValue && !modelNumbers.includes(inputValue)) {
			updateModelNumbers(inputValue)
		}
		setInputValue("")
	}

	const inputChangeHandle = (e: TOnChangeInput) => {
		const value = e.target.value
		setInputValue(value)
	}

	const onCloseHandle = (removedTag: string) => {
		const updatedModelNumbers = modelNumbers.filter((tag) => tag !== removedTag)
		setModelNumbers(updatedModelNumbers)
	}

	return (
		<div className={editableModelNumbers}>
			<div className={tags}>
				{modelNumbers.map((modelNumber) => (
					<Tag key={modelNumber} closeIcon={<CloseIcon />} onClose={() => onCloseHandle(modelNumber)}>
						{modelNumber}
					</Tag>
				))}
			</div>
			<InputBorderless
				inputChangeHandle={inputChangeHandle}
				onConfirmHandle={onConfirmHandle}
				value={inputValue}
				placeholder="Press Enter to add a Number"
			/>
		</div>
	)
}
