export const TimeIcon = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.5703 2.5C7.33131 2.5 3.07031 6.762 3.07031 12C3.07031 17.239 7.33131 21.5 12.5703 21.5C17.8083 21.5 22.0703 17.239 22.0703 12C22.0703 6.762 17.8083 2.5 12.5703 2.5Z"
				fill="#CCDAFF"
			/>
			<path
				d="M15.4152 15.3211C15.6702 15.3211 15.9192 15.1911 16.0602 14.9561C16.2732 14.6011 16.1572 14.1401 15.8012 13.9271L12.9922 12.2451V8.58105C12.9922 8.16605 12.6562 7.83105 12.2422 7.83105C11.8282 7.83105 11.4922 8.16605 11.4922 8.58105V12.6701C11.4922 12.9341 11.6302 13.1791 11.8572 13.3141L15.0322 15.2141C15.1522 15.2871 15.2852 15.3211 15.4152 15.3211Z"
				fill="#0047FF"
			/>
		</svg>
	)
}
