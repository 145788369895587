import { H1 } from "components/ui/typography/titles/Titles"
import { ButtonSecondaryForwardRef } from "components/ui/buttons/ButtonSecondary/ButtonSecondary"
import { ButtonPrimary } from "components/ui/buttons/ButtonPrimary/ButtonPrimary"
import { Tooltip } from "components/ui/tooltip/Tooltip"
import { copyToClipboard } from "helpers/utils.helper"
import { useMessage } from "hooks/message.hook"
import { useInviteUser } from "hooks/user.hook"
import style from "./PageTeamHeader.module.scss"

const { pageTeamHeader, wrapper, buttons, text } = style

export const PageTeamHeader = () => {
	const { mutate: inviteUserMutate } = useInviteUser()
	const { success, error } = useMessage()
	const origin = window.location.origin

	const onCopyLinkHandle = () => {
		inviteUserMutate(undefined, {
			onSuccess: (response) => {
				if (response) {
					const data = response.data
					const inviteLink = `${origin}/signup?token=${data.registration_token}`
					copyToClipboard(inviteLink)
				}
			},
			onError: () => {
				error("Something went wrong, please try again later")
			},
		})
		success("The link has been copied")
	}

	return (
		<div className={pageTeamHeader}>
			<div className={wrapper}>
				<H1>Team members</H1>
				<div className={buttons}>
					<Tooltip text="Invite new people with this link" placement="top">
						<ButtonSecondaryForwardRef onClick={onCopyLinkHandle}>Copy invitation link</ButtonSecondaryForwardRef>
					</Tooltip>
					<ButtonPrimary submittable={false}>Invite People</ButtonPrimary>
				</div>
			</div>
			<p className={text}>Here you can invite new users, deactivate, or delete them.</p>
		</div>
	)
}
