import { ReactNode } from "react"
import { ConfigProvider } from "antd"

type Props = {
	children: ReactNode
}

export const TooltipProvider = ({ children }: Props) => {
	return (
		<ConfigProvider
			theme={{
				components: {
					Tooltip: {
						colorTextLightSolid: "#000",
						paddingSM: 32,
						paddingXS: 20,
					},
				},
			}}
		>
			{children}
		</ConfigProvider>
	)
}
