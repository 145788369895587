export const SuperAdminIcon = () => {
	return (
		<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M1.5 11.4681V4.28313C1.5 3.28563 2.0775 3.04563 2.7825 3.75063L4.725 5.69313C5.0175 5.98563 5.4975 5.98563 5.7825 5.69313L8.4675 3.00062C8.76 2.70813 9.24 2.70813 9.525 3.00062L12.2175 5.69313C12.51 5.98563 12.99 5.98563 13.275 5.69313L15.2175 3.75063C15.9225 3.04563 16.5 3.28563 16.5 4.28313V11.4756C16.5 13.7256 15 15.2256 12.75 15.2256H5.25C3.18 15.2181 1.5 13.5381 1.5 11.4681Z"
				stroke="#0047FF"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
