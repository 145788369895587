import style from "./UploadbarStatusBadge.module.scss"
import type { TUploadStatus } from "types/uploadFiles.type"
const { badge, uploading, uploaded, canceled, failed } = style

type Props = {
	status: TUploadStatus
}

export const UploadbarStatusBadge = ({ status }: Props) => {
	switch (status) {
		case "uploading":
			return <div className={`${badge} ${uploading}`}>Uploading</div>
		case "uploaded":
			return <div className={`${badge} ${uploaded}`}>Uploaded</div>
		case "canceled":
			return <div className={`${badge} ${canceled}`}>Canceled</div>
		case "failed":
			return <div className={`${badge} ${failed}`}>Failed</div>
	}
}
