import { useState } from "react"
import { ThumbsUpIcon } from "components/ui/icons/generalFeedback/ThumbsUpIcon"
import { ThumbsDownIcon } from "components/ui/icons/generalFeedback/ThumbsDownIcon"
import { useGeneralFeedbackStore, useThankYouMessageStore } from "store/drawer.store"
import { useMessage } from "hooks/message.hook"
import { NegativeFeedbackModal } from "../NegativeFeedbackModal/NegativeFeedbackModal"
import { useSearchParams } from "react-router-dom"
import { usePostGeneralFeedback } from "hooks/drawings.hook"
import styles from "./GeneralFeedback.module.scss"

const { generalFeedbackStyle, text, icons, icon, active } = styles

export const GeneralFeedback = () => {
	const { generalFeedback, setGeneralFeedback } = useGeneralFeedbackStore()
	const { setThankYouMessage } = useThankYouMessageStore()

	const { success: successMessage, error: errorMessage } = useMessage()
	const [isModalOpen, setIsModalOpen] = useState(false)

	const [searchParams] = useSearchParams()

	const { mutate: postGeneralFeedback } = usePostGeneralFeedback()

	// Get drawingId and processingId from URL or store
	const drawingId = Number(searchParams.get("drawingID"))
	const processingId = Number(searchParams.get("processingID"))

	// Get processingUrl from current window location
	const processingUrl = window.location.href

	const onClickHandle = (type: string) => {
		const formData = new FormData()
		formData.append("type", "POSITIVE")
		formData.append("processing_url", processingUrl)

		switch (type) {
			case "thumbsUp":
				setGeneralFeedback(type) // Set feedback type

				// Post positive feedback to server
				postGeneralFeedback(
					{ formData, drawingId, processingId },
					{
						onSuccess: () => {
							successMessage("Thank you for your feedback!")
						},
						onError: () => {
							errorMessage("Something went wrong, please try again later")
						},
					},
				)
				break

			// Open negative feedback modal
			case "thumbsDown":
				setIsModalOpen(true)
				break
		}
	}

	const modalCloseHandle = () => {
		setIsModalOpen(false)
		setThankYouMessage(false)
	}

	return (
		<>
			<div className={generalFeedbackStyle}>
				<span className={text}>Give us feedback</span>
				<div className={icons}>
					<div
						className={`${icon} ${generalFeedback === "thumbsUp" ? active : ""}`}
						onClick={() => onClickHandle("thumbsUp")}
						data-testid="thumbsUp"
					>
						<ThumbsUpIcon active={generalFeedback === "thumbsUp"} />
					</div>
					<div
						className={`${icon} ${generalFeedback === "thumbsDown" ? active : ""}`}
						onClick={() => onClickHandle("thumbsDown")}
						data-testid="thumbsDown"
					>
						<ThumbsDownIcon active={generalFeedback === "thumbsDown"} />
					</div>
				</div>
			</div>
			<NegativeFeedbackModal
				drawingId={drawingId}
				processingId={processingId}
				isModalOpen={isModalOpen}
				processingUrl={processingUrl}
				setModalClose={modalCloseHandle}
			/>
		</>
	)
}
