import { useCallback, useEffect } from "react"
import { useDropzone } from "react-dropzone"
import { UploadFileIcon } from "components/ui/icons/generalFeedback/UploadFileIcon"
import { CancelUploadIcon } from "components/ui/icons/uploadbar/CancelUploadIcon"
import { IExtendedFeedbackFile } from "../../NegativeFeedbackModal"
import styles from "./Screenshots.module.scss"

const { screenshot, textBlue, text, preview, previewsBlock, image, cancelUpload } = styles

type Props = {
	onFilesAdded: (files: IExtendedFeedbackFile[]) => void
	files: IExtendedFeedbackFile[]
	onRemoveFile: (id: number) => void
}

export const Screenshots = ({ onFilesAdded, files, onRemoveFile }: Props) => {
	const onDrop = useCallback(
		(acceptedFiles: File[]) => {
			const extendedFiles = acceptedFiles.map((file) =>
				Object.assign(file, {
					preview: URL.createObjectURL(file),
					id: Date.now(),
				}),
			) as IExtendedFeedbackFile[]
			onFilesAdded(extendedFiles)
		},
		[onFilesAdded],
	)

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		accept: { "image/*": [] },
	})

	const PasteHandle = useCallback(
		(event: ClipboardEvent) => {
			const items = event.clipboardData?.items
			const pastedFiles = []

			if (items) {
				for (let i = 0; i < items.length; i++) {
					if (items[i].type.indexOf("image") !== -1) {
						const file = items[i].getAsFile()
						pastedFiles.push(file)
					}
				}
			}

			if (pastedFiles.length > 0) {
				onFilesAdded(pastedFiles as IExtendedFeedbackFile[])
			}
		},
		[onFilesAdded],
	)

	useEffect(() => {
		document.addEventListener("paste", PasteHandle as EventListener)
		return () => {
			document.removeEventListener("paste", PasteHandle as EventListener)
		}
	}, [PasteHandle])

	return (
		<>
			<div {...getRootProps()} onPaste={(e) => PasteHandle(e as unknown as ClipboardEvent)} className={screenshot}>
				<input data-testid="fileInput" {...getInputProps()} />
				<UploadFileIcon />
				<div className={text} data-testid="dropzone">
					{isDragActive ? (
						<span>Drop the images here ...</span>
					) : (
						<span>
							<span className={textBlue}>Upload a file</span>, drag and drop or paste here
						</span>
					)}
				</div>
			</div>
			<div className={previewsBlock}>
				{files.map((file) => (
					<div className={preview} key={file.id}>
						<button className={cancelUpload} onClick={() => onRemoveFile(file.id)} data-testid="cancelUpload">
							<CancelUploadIcon />
						</button>

						<div className={image}>
							<img data-testid="filePreview" src={file.preview} alt={`Preview ${file.name}`} />
						</div>
					</div>
				))}
			</div>
		</>
	)
}
