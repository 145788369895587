import { ConfigProvider } from "antd"
import { ReactNode } from "react"

type Props = {
	children: ReactNode
}

export const TagProvider = ({ children }: Props) => {
	return (
		<ConfigProvider
			theme={{
				components: {
					Tag: {
						defaultBg: "#F2F3F7",
					},
				},
				token: {
					fontSizeSM: 18,
					borderRadiusSM: 6,
				},
			}}
		>
			{children}
		</ConfigProvider>
	)
}
