export const UploadFileIcon = () => {
	return (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M14.9987 36.6673H24.9987C33.332 36.6673 36.6654 33.334 36.6654 25.0007V15.0007C36.6654 6.66732 33.332 3.33398 24.9987 3.33398H14.9987C6.66536 3.33398 3.33203 6.66732 3.33203 15.0007V25.0007C3.33203 33.334 6.66536 36.6673 14.9987 36.6673Z"
				fill="#F2F3F7"
				stroke="#BEC2D6"
				strokeWidth="1.6"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M15.0013 16.6667C16.8423 16.6667 18.3346 15.1743 18.3346 13.3333C18.3346 11.4924 16.8423 10 15.0013 10C13.1604 10 11.668 11.4924 11.668 13.3333C11.668 15.1743 13.1604 16.6667 15.0013 16.6667Z"
				fill="#F2F3F7"
				stroke="#BEC2D6"
				strokeWidth="1.6"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M4.45312 31.5826L12.6698 26.066C13.9865 25.1826 15.8865 25.2826 17.0698 26.2993L17.6198 26.7826C18.9198 27.8993 21.0198 27.8993 22.3198 26.7826L29.2531 20.8326C30.5531 19.716 32.6531 19.716 33.9531 20.8326L36.6698 23.166"
				fill="#F2F3F7"
			/>
			<path
				d="M4.45312 31.5826L12.6698 26.066C13.9865 25.1826 15.8865 25.2826 17.0698 26.2993L17.6198 26.7826C18.9198 27.8993 21.0198 27.8993 22.3198 26.7826L29.2531 20.8326C30.5531 19.716 32.6531 19.716 33.9531 20.8326L36.6698 23.166"
				stroke="#BEC2D6"
				strokeWidth="1.6"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
