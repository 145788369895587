import { useCallback, useMemo, useState } from "react"
import { Dropdown } from "components/ui/dropdown/Dropdown"
import { Input } from "components/ui/inputs/Input"
import type { MenuProps } from "antd"
import { DeleteIcon } from "components/ui/icons/delete/DeleteIcon"
import { useDeleteKeyword } from "hooks/keywords.hook"
import { useMessage } from "hooks/message.hook"
import { AxiosError } from "axios"
import { Keyword } from "../Keyword/Keyword"
import style from "./KeywordsByAlphabet.module.scss"

const { keywordsByAlphabet, letterBox, keywordsBox, letter, deleteBlock } = style

type TKeywords = {
	keywordId: number
	keywordColor: string
	textColor: string
	colorPriority?: number
	colorName?: string
	colorId?: number
}

type TAlphabetKeywords = Record<string, TKeywords[]>
type KeywordsProps = { keywords: TAlphabetKeywords; refetchKeywords: () => void }

type TDropDownChange = { source: string }

export const KeywordsByAlphabet = ({ keywords, refetchKeywords }: KeywordsProps) => {
	const { mutate: deleteKeyword } = useDeleteKeyword()
	const { success, error } = useMessage()

	const [openDropdown, setOpenDropdown] = useState<string | null>(null)
	const [alphabet, setAlphabet] = useState<string[]>([])

	const deleteKeywordHandle = useCallback(
		(id: number) => {
			deleteKeyword(id, {
				onSuccess: () => {
					success("Keyword deleted")
					refetchKeywords()
				},
				onError: (e) => {
					if (e instanceof AxiosError) {
						error("Something went wrong, please try again later")
					}
				},
			})
		},
		[deleteKeyword, success, error, refetchKeywords],
	)

	const dropDownItems = (id: number): MenuProps["items"] => [
		{
			key: "1",
			label: <Input disabled={true} size="middle" />,
		},
		{
			type: "divider",
		},
		{
			key: "2",
			type: "group",
			label: "Color",
			children: [
				{
					key: "1-1",
					label: "Yellow",
					disabled: true,
				},
				{
					key: "1-2",
					label: "Orange",
					disabled: true,
				},
				{
					key: "1-3",
					label: "Green",
					disabled: true,
				},
				{
					key: "1-4",
					label: "Blue",
					disabled: true,
				},
				{
					key: "1-5",
					label: "Pink",
					disabled: true,
				},
				{
					key: "1-6",
					label: "Purple",
					disabled: true,
				},
			],
		},
		{
			type: "divider",
		},
		{
			key: "3",
			label: (
				<div className={deleteBlock}>
					<DeleteIcon />
					<span>Delete keyword</span>
				</div>
			),
			danger: true,
			onClick: () => deleteKeywordHandle(id),
		},
	]

	useMemo(() => {
		if (keywords) {
			const alphabet = Object.keys(keywords)
			setAlphabet(alphabet)
		}
	}, [keywords])

	const onItemClickHandle = useCallback((e: { key: string }) => {
		if (e.key !== "1") setOpenDropdown(null)
	}, [])

	const openDropDownChangeHandle = useCallback((nextOpen: boolean, info: TDropDownChange, uniqueKey: string) => {
		if (info.source === "trigger") {
			setOpenDropdown(nextOpen ? uniqueKey : null)
		}
	}, [])

	return (
		<div className={keywordsByAlphabet}>
			{alphabet &&
				alphabet.map((letterItem) => (
					<div className={letterBox} key={letterItem}>
						<span className={letter}>{letterItem.toUpperCase()}</span>
						<div className={keywordsBox}>
							{keywords[letterItem] &&
								Object.entries(keywords[letterItem]).map(([keywordName, keywordDetails]) => {
									const uniqueKey = `${letterItem}-${keywordDetails.keywordId}`

									return (
										<Dropdown
											items={dropDownItems(keywordDetails.keywordId)}
											onClick={onItemClickHandle}
											onOpenChange={(nextOpen, info) => openDropDownChangeHandle(nextOpen, info, uniqueKey)}
											key={uniqueKey}
											open={openDropdown?.toString() === uniqueKey}
										>
											<div>
												<Keyword
													style={{
														backgroundColor: keywordDetails.keywordColor,
														color: keywordDetails.textColor,
														cursor: "pointer",
													}}
												>
													{keywordName}
												</Keyword>
											</div>
										</Dropdown>
									)
								})}
						</div>
					</div>
				))}
		</div>
	)
}
