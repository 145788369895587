import { Modal as AntModal } from "antd"
import { ModalProvider } from "components/providers/Modal.provider"
import { ReactNode } from "react"

type Props = {
	className?: string
	title?: string
	children: ReactNode
	width?: number | string
	centered?: boolean
	isModalOpen: boolean
	setModalClose: () => void
}

export const Modal = ({
	className = "",
	title,
	children,
	width = 460,
	centered = true,
	isModalOpen,
	setModalClose,
}: Props) => {
	return (
		<ModalProvider>
			<AntModal
				className={className}
				title={title}
				width={width}
				open={isModalOpen}
				onCancel={setModalClose}
				footer={false}
				centered={centered}
			>
				{children}
			</AntModal>
		</ModalProvider>
	)
}
