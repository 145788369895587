export const ConfigurationIcon = () => {
	return (
		<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect x="1" y="1" width="7" height="4" rx="2" fill="#0047FF" stroke="#0047FF" strokeWidth="1.5" />
			<rect x="1" y="8" width="7" height="10" rx="2.5" fill="#0047FF" stroke="#0047FF" strokeWidth="1.5" />
			<rect
				opacity="0.3"
				x="11"
				y="1"
				width="7"
				height="10"
				rx="2.5"
				fill="#0047FF"
				stroke="#0047FF"
				strokeWidth="1.5"
			/>
			<rect x="11" y="14" width="7" height="4" rx="2" fill="#0047FF" stroke="#0047FF" strokeWidth="1.5" />
		</svg>
	)
}
