export const ExternalUserIcon = () => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M10 1.99841L10 4.39844"
				stroke="#FF7900"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path d="M10 15.6L10 18" stroke="#FF7900" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
			<path
				d="M17.9997 10L15.5996 10"
				stroke="#FF7900"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path d="M4.40011 10L2 10" stroke="#FF7900" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
			<path
				opacity="0.3"
				d="M15.6581 4.34199L13.9609 6.03906"
				stroke="#FF7900"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				opacity="0.3"
				d="M6.04284 13.9592L4.3457 15.6562"
				stroke="#FF7900"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				opacity="0.3"
				d="M4.34388 4.34199L6.04102 6.03906"
				stroke="#FF7900"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				opacity="0.3"
				d="M13.9611 13.9592L15.6582 15.6562"
				stroke="#FF7900"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
