import { ObjectsCategories } from "../ObjectsCategories/ObjectsCategories"
import { Keywords } from "../Keywords/Keywords"

export const ConfigurationContent = ({ index }: { index: number }) => {
	switch (index) {
		case 0:
			return <Keywords />
		case 1:
			return <ObjectsCategories />

		default:
			return <Keywords />
	}
}
