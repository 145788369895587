import { useCollapseObjects } from "hooks/collapseObjects.hook"
import { useObjectsCategories } from "hooks/objectsCategories.hook"
import { CategoriesColumns } from "./CategoriesColumns"
import { Collapse } from "components/ui/collapse/Collapse"
import { Skeleton } from "components/ui/skeleton/Skeleton"
import { isAxiosError } from "helpers/utils.helper"
import { ModelNumbersModal } from "../ModelNumbersModal/ModelNumbersModal"
import style from "./Categories.module.scss"

const { errorMessage } = style

export const Categories = () => {
	const { data, isPending, error, refetch } = useObjectsCategories()
	const tableColumns = CategoriesColumns()

	const { generateCollapseItems } = useCollapseObjects(tableColumns)
	const collapseItems = generateCollapseItems(data)

	if (isPending) {
		return (
			<div data-testid="skeleton">
				<Skeleton rows={12} />
			</div>
		)
	}

	if (error) {
		if (isAxiosError(error)) {
			const status = error.response?.status
			if (status === 404) {
				return <div className={errorMessage}>Objects categories config not found</div>
			} else {
				return <div className={errorMessage}>Something went wrong, please try again later</div>
			}
		} else {
			return <div className={errorMessage}>An unexpected error occurred</div>
		}
	}

	return (
		<>
			<div data-testid="collapse">
				<Collapse items={collapseItems} />
			</div>
			<ModelNumbersModal refetchObjectCategories={refetch} />
		</>
	)
}
