import { axiosNonAuthInstance, axiosInstance } from "api/axios.instance"

class AuthService {
	static USERS = "users"
	static OAUTH2 = "oauth2/token"
	static REGISTER = "registration"

	async login(email: string, password: string) {
		return axiosNonAuthInstance.post(`${AuthService.OAUTH2}/`, {
			email: email,
			password: password,
		})
	}

	async destroyToken() {
		return axiosInstance.post(`${AuthService.OAUTH2}/terminate/`)
	}

	async register(
		email: string,
		firstName: string,
		lastName: string,
		password: string,
		confirmPassword: string,
		token: string | undefined | null,
	) {
		return axiosNonAuthInstance.post(`${AuthService.USERS}/`, {
			email: email,
			first_name: firstName,
			last_name: lastName,
			password: password,
			re_password: confirmPassword,
			registration_token: token,
		})
	}

	async resendInvintation(email: string) {
		return axiosNonAuthInstance.post(`${AuthService.USERS}/resend_activation/`, {
			email: email,
		})
	}

	async activateUser(id: string, token: string) {
		return axiosNonAuthInstance.post(`${AuthService.USERS}/activation/`, {
			uid: id,
			token: token,
		})
	}

	async checkRegisterToken(token: string | null) {
		return axiosNonAuthInstance.get(`${AuthService.REGISTER}/token/${token}/`)
	}
}

export default new AuthService()
