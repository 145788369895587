import { H2 } from "components/ui/typography/titles/Titles"
import { ButtonPrimary } from "components/ui/buttons/ButtonPrimary/ButtonPrimary"
import { useNavigate } from "react-router-dom"
import style from "./SuccessfullCheck.module.scss"

const { title, text } = style

export const SuccessfullCheck = () => {
	const navigate = useNavigate()
	return (
		<>
			<H2 className={title}>Account activated!</H2>
			<p className={text}>
				Congratulations, your account has been successfully activated. Now you can log in and use all the features of
				Stevens Workspace!
			</p>
			<ButtonPrimary onClick={() => navigate("/login")}>Log in</ButtonPrimary>
		</>
	)
}
